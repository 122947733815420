import React, { useState, useEffect } from "react"
import {
  Image,
  Modal,
  Descriptions,
  Row,
  Col,
  Spin,
  Divider,
  Typography,
} from "antd"
import SVGFromURL from "components/svg2comp"

import * as Http from "utils/http.helper"
const { Text } = Typography

const allPositions = [
  {
    name: "Front Center",
    code: "front_center",
  },
  {
    name: "Spine",
    code: "spine",
  },
  {
    name: "Front Vertical on the Right",
    code: "front_vertical_on_the_right",
  },
  {
    name: "Front Left Lower Corner",
    code: "front_left_lower_corner",
  },
  {
    name: "Front Bottom Center",
    code: "front_bottom_center",
  },
  {
    name: "Inside Center",
    code: "inside_center",
  },
  {
    name: "Inside Upper Side",
    code: "inside_upper_side",
  },
  {
    name: "Inside Left Lower Corner",
    code: "inside_left_lower_corner",
  },
  {
    name: "Inside Left Side",
    code: "inside_left_side",
  },
  {
    name: "Inside Right Side",
    code: "inside_right_side",
  },
  {
    name: "Back Center",
    code: "back_center",
  },
  {
    name: "Back Right Lower Corner",
    code: "back_right_lower_corner",
  },
  {
    name: "Back Bottom Center",
    code: "back_bottom_center",
  },
  {
    name: "Stones",
    code: "stones",
  },
  {
    name: "Tools",
    code: "tools",
  },
  {
    name: "Guitar Pick",
    code: "guitar_pick",
  },
  {
    name: "Front Right Lower Corner",
    code: "front_right_lower_corner",
  },
]

const TemplateDesignModal = ({ setTempDesignModal, tempDesignModal }) => {
  const [template, setTemplate] = useState([])
  const [design, setDesign] = useState([])
  const [loading, setLoading] = useState(false)
  const getSkuImage = () => {
    Http.get(
      `api/orders/GetEngravingTemplateImages?sku=${tempDesignModal?.data?.sku}`
    )
      .then(res => {
        if (res.data.length > 0) {
          setTemplate(res.data)

          setLoading(false)
        }
      })
      .catch(function (error) {})

    Http.get(
      `api/orders/GetEngravingOrderImages?orderId=${tempDesignModal?.data?.id}${
        tempDesignModal?.customerId
          ? `&customerId=${tempDesignModal.customerId}`
          : ""
      }`
    )
      .then(res => {
        if (res.data.length > 0) {
          setDesign(res.data)
          setLoading(false)
        }
      })
      .catch(function (error) {})
  }

  useEffect(() => {
    if (tempDesignModal?.data?.orderId) {
      setLoading(true)
      getSkuImage()
    }
  }, [tempDesignModal])

  return (
    <Modal
      title={
        <>
          Oder Details{" "}
          <span style={{ fontSize: 14 }}>
            <span style={{ opacity: "0.3" }}>[ Order: </span>
            {tempDesignModal?.data?.orderId}{" "}
            <span style={{ opacity: "0.3" }}>| Sku: </span>
            {tempDesignModal?.data?.sku}
            <span style={{ opacity: "0.3" }}> ]</span>
          </span>
        </>
      }
      open={true}
      maskClosable={false}
      width={800}
      centered
      onCancel={() => {
        setTempDesignModal({ show: false, data: null, customerId: null })
      }}
      cancelText="No"
      onOk={() => {
        setTempDesignModal({ show: false, data: null, customerId: null })
      }}
    >
      <Spin spinning={loading}>
        {design.length > 0 && template.length > 0 && (
          <Row gutter={[16, 16]}>
            <Divider orientation="left" className="mt-3">
              {tempDesignModal?.data?.productName} /{" "}
              {tempDesignModal?.data?.price} $
            </Divider>
            {[10, 20, 30, 40, 50, 60, 70].map(positionCodeValue => {
              return (
                design.find(x => x.type === positionCodeValue) &&
                design
                  .find(x => x.type === positionCodeValue)
                  .positions.map((item, index) => {
                    let tempFilter = template.filter(
                      x => x.type === positionCodeValue
                    )
                    let tempFind = tempFilter.find(
                      x => x.positionCodes[0] === item.positionCode
                    )
                    let title = allPositions.find(
                      x => x.code === item.positionCode
                    )

                    return (
                      <Col lg={8} key={index + item.positionCode}>
                        <Descriptions
                          title={title.name}
                          bordered
                          layout="vertical"
                          className="mb-3"
                        >
                          <Descriptions.Item label="Template">
                            <style>
                              {`.ant-modal-body{
                                     max-height:680px;
                                     overflow-x:hidden!important;
                                     overflow-y:auto!important;
                                     padding-right:10px!important;
                                 }
                                .ant-descriptions-item-content{
                                  padding:0!important;
                                  text-align:center!important;
                                }

                               
                                path.st0,
                                .st_0,
                                path.st1,
                                line.st1,
                                polyline.st3{
                                    fill:${
                                      tempFind && tempFind.colorHexCode
                                    }!important;
                                    stroke:${
                                      tempFind.colorHexCode === "#111111"
                                        ? "grey"
                                        : "black"
                                    }!important;
                                    stroke-width:20px!important;
                                   }
                                   rect{
                                    fill:${tempFind.colorHexCode}!important;
                                    stroke:black!important;
                                    stroke-width:25px!important;
                                  }
                                  polygon{
                                    fill:white!important;
                                    stroke:${tempFind.colorHexCode}!important;
                                    stroke-width:25px!important;
                                 }
                                g g polyline,
                                g g line{
                                     fill:${tempFind.colorHexCode}!important;
                                     stroke:blue!important;
                                   }`}
                            </style>
                            <SVGFromURL
                              className="template_Svg"
                              url={tempFind && tempFind.imageUrl}
                              width={120}
                              height={120}
                            />
                          </Descriptions.Item>
                          <Descriptions.Item label="Design">
                            <Image
                              src={item.imgUrl}
                              width={80}
                              style={{ background: "#dfdfdf" }}
                            />
                          </Descriptions.Item>
                        </Descriptions>
                      </Col>
                    )
                  })
              )
            })}
            {design?.length > 0 && design[0].note && (
              <Col lg={24}>
                <Text type="danger">
                  <span style={{ fontWeight: "bold" }}>Notes: </span>
                  {design[0].note}
                </Text>
              </Col>
            )}
          </Row>
        )}
      </Spin>
    </Modal>
  )
}

export default TemplateDesignModal
