import React, { useState, useEffect } from "react"
import Breadcrumbs from "components/Common/Breadcrumb"
import * as Http from "utils/http.helper"
import {
  Table,
  Button,
  Select,
  Divider,
  Form,
  DatePicker,
  Spin,
  Drawer,
  Row,
  Tooltip,
  Col,
  Card,
} from "antd"
import { SearchOutlined, ClearOutlined, EyeFilled } from "@ant-design/icons"
import toastr from "toastr"
import dayjs from "dayjs"
import customParseFormat from "dayjs/plugin/customParseFormat"
dayjs.extend(customParseFormat)
const { RangePicker } = DatePicker
const css = `.ant-form-item{
  margin-bottom:0!important
}`
let dateFormat = "DD/MM/YYYY"
let apiToDateFormat = "MM-DD-YYYY"
const today = dayjs()
const tomorrow = today.add(1, "day")
const startOfMonth = dayjs().startOf("month")
const DeriFinancial = () => {
  document.title = "Apparel Financial | EASY JET CONNECT"
  const [loading, setLoading] = useState(true)
  const [formSearch] = Form.useForm()
  const [companyForm] = Form.useForm()
  const [value, setValue] = useState(null)
  const [data, setData] = useState([])
  const [totalInfo, setTotalInfo] = useState({
    count: 0,
    price: 0,
  })
  const [infoLoading, setInfoLoading] = useState(false)
  const [customers, setCustomers] = useState("")
  const [drawer, setDrawer] = useState({ show: false, data: null })
  const [detailData, setDetailData] = useState([])
  const [customersV2, setCustomersV2] = useState([])

  const getData = () => {
    setLoading(true)
    Http.get(`api/accounting/GetCustomerApparelRecordPrice`)
      .then(res => {
        if (res.data.length > 0) {
          setData(res.data)
          setLoading(false)
        } else {
          setLoading(false)
        }
      })
      .catch(function (error) {
        setLoading(false)
      })
  }
  useEffect(() => {
    if (JSON.parse(localStorage.getItem("authUserV0"))?.roleId === 2) {
      getData()
    }
  }, [])

  const columnsCount = [
    {
      title: "Record Name",
      dataIndex: "recordName",
    },
    {
      title: "Total Order",
      dataIndex: "totalCount",
    },
    {
      title: "Total Price",
      dataIndex: "totalPrice",
    },

    {
      title: " ",
      key: "action",
      width: 50,
      render: (_, record) => {
        return (
          <Tooltip placement="top" title="Detail">
            <Button
              type="dashed"
              className="btn btn_primary d-flex align-items-center justify-content-center"
              icon={<EyeFilled />}
              onClick={() => {
                if (
                  JSON.parse(localStorage.getItem("authUserV0"))?.roleId === 2
                ) {
                  getDetailByRecordId(record)
                } else {
                  getCompanyDetailByRecordId(record)
                }
              }}
            />
          </Tooltip>
        )
      },
    },
  ]

  // ------ 2. düzey finansal ekran ----
  const getAllCustomers = () => {
    Http.get(`api/companies/GetAllCustomers?categoryId=1`)
      .then(res => {
        if (res?.success) {
          let arr = [{ value: "", label: "All Customer" }]
          let arrV2 = []
          res.data.map(item => {
            arrV2.push({ value: item.id, label: item.name })
          })
          setCustomers(arr.concat(arrV2))
          setCustomersV2(arrV2)
          setValue([startOfMonth, tomorrow])
          companyForm.setFieldsValue({
            customer_id: arrV2[0].value,
          })
          getDetailCompany()
          formSearch.setFieldsValue({
            customerId: "",
          })
        }
      })
      .catch(function (error) {
        toastr.error(error)
      })
  }

  const getDetailCompany = () => {
    setLoading(true)
    const { customer_id } = companyForm.getFieldsValue()
    //company Admin
    Http.get(
      `api/Accounting/GetCompanyApparelRecordPrice?customerId=${customer_id}`
    ).then(res => {
      if (res.success) {
        setData(res?.data)
        setLoading(false)
      }
    })
  }

  useEffect(() => {
    if (JSON.parse(localStorage.getItem("authUserV0")).roleId === 1) {
      getAllCustomers()
    }
    formSearch.setFieldsValue({
      dates: [startOfMonth, tomorrow],
    })
  }, [])

  const getDatas = () => {
    setInfoLoading(true)
    const formValue = formSearch.getFieldsValue()
    if (JSON.parse(localStorage.getItem("authUserV0"))?.roleId === 2) {
      //customer Admin
      Http.get(
        `api/Accounting/GetCustomerApparelEngravingPriceSummary?categoryId=1&startDate=${
          formValue?.dates
            ? dayjs(formValue?.dates[0]).format(apiToDateFormat)
            : ""
        }&endDate=${
          formValue?.dates
            ? dayjs(formValue?.dates[1]).format(apiToDateFormat)
            : ""
        }`
      )
        .then(res => {
          if (res.success) {
            setTotalInfo({
              count: res?.data?.orderCount,
              price: res?.data?.totalPrice,
            })
            setInfoLoading(false)
          }
        })
        .catch(function (error) {
          setInfoLoading(false)
        })
    } else if (JSON.parse(localStorage.getItem("authUserV0"))?.roleId === 1) {
      //company Admin
      Http.get(
        `api/Accounting/GetCompanyApparelEngravingPriceSummary?categoryId=1&customerId=${
          formValue?.customerId ? formValue?.customerId : ""
        }&startDate=${
          formValue?.dates
            ? dayjs(formValue?.dates[0]).format(apiToDateFormat)
            : ""
        }&endDate=${
          formValue?.dates
            ? dayjs(formValue?.dates[1]).format(apiToDateFormat)
            : ""
        }`
      )
        .then(res => {
          if (res.success) {
            setTotalInfo({
              count: res?.data?.orderCount,
              price: res?.data?.totalPrice,
            })
          }
          setInfoLoading(false)
        })
        .catch(function (error) {
          setInfoLoading(false)
        })
    }
  }

  useEffect(() => {
    getDatas()
  }, [])

  const columnsDetail = [
    {
      title: "Product Name",
      dataIndex: "productName",
    },
    {
      title: "Total Count",
      dataIndex: "totalCount",
    },
    {
      title: "Total Price",
      dataIndex: "totalPrice",
      render: (_, row) => {
        return row.totalPrice ? row.totalPrice.toFixed(2) + " $" : ""
      },
    },
  ]
  const getDetailByRecordId = record => {
    setLoading(true)
    Http.get(
      `api/Accounting/GetCustomerApparelRecordDetailPrice?recordName=${record?.recordName}`
    ).then(res => {
      if (res.success) {
        if (res?.data.length > 0) {
          setDetailData(res?.data)
          setDrawer({ show: true, data: record })
        } else {
          toastr.error(
            "Bu customer için engraving detay bilgilerine uluşılamadı. Engraving kaydı olduğunundan emin olun!"
          )
        }
        setLoading(false)
      }
    })
  }
  const getCompanyDetailByRecordId = record => {
    // setLoading(true)
    const { customer_id } = companyForm.getFieldsValue()
    alert(`İşlem yapılıcak. ${customer_id}
      ${record?.recordName.replaceAll("/", "-")}`)
    // Http.get(
    //   `api/Accounting/GetCompanyApparelRecordDetailPrice?customerId=${customer_id}&recordName=${record?.recordName.replaceAll(
    //     "/",
    //     "-"
    //   )}`
    // ).then(res => {
    //   if (res.success) {
    //     if (res?.data.length > 0) {
    //       setDetailData(res?.data)
    //       setDrawer({ show: true, data: record })
    //     } else {
    //       toastr.error(
    //         "Bu kayıt için detay bilgilerine uluşılamadı. Lütfen bildiriniz!"
    //       )
    //     }
    //     setLoading(false)
    //   }
    // })
  }
  return (
    <React.Fragment>
      <style>{css}</style>
      <div className="page-content mb-3">
        <Row gutter={[16, 16]}>
          <Col lg={16}>
            <Card className="mini-stats-wid mt-3" hoverable>
              <Row gutter={[16, 16]}>
                <Divider orientation="left">
                  Apparel Financial Info
                  <span style={{ opacity: "0.6", fontSize: 14 }}>
                    {value
                      ? ` [
                      ${dayjs(value[0]).format(dateFormat)} /
                       ${dayjs(value[1]).format(dateFormat)}
                    ]`
                      : ``}
                  </span>
                </Divider>
                <Col lg={8}>
                  <div className="ui">
                    <div className="ui_box">
                      <div className="ui_box__inner">
                        <h2>Total Order Count</h2>
                        <div className="stat_left">
                          {!infoLoading ? (
                            <h1>{totalInfo?.count}</h1>
                          ) : (
                            <Spin spinning={true} />
                          )}
                        </div>
                        <div className="progress_graph">
                          <div className="progress_graph__bar--1"></div>
                          <div className="progress_graph__bar--2"></div>
                          <div className="progress_graph__bar--3"></div>
                          <div className="progress_graph__bar--4"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col lg={8}>
                  <div className="ui">
                    <div className="ui_box">
                      <div className="ui_box__inner">
                        <h2>Total Price</h2>
                        <div className="stat">
                          {!infoLoading ? (
                            <h1>
                              {" "}
                              <span>${totalInfo.price.toFixed(2)}</span>{" "}
                            </h1>
                          ) : (
                            <Spin spinning={true} />
                          )}
                        </div>
                        <div className="progress">
                          <div
                            className="progress_bar"
                            style={{
                              width: `${
                                (Math.round(totalInfo?.price) * 100) / 5000
                              }%`,
                            }}
                          ></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col lg={24}>
                  <Divider orientation="left">Search Criteria</Divider>
                  <Form form={formSearch} layout="vertical">
                    <Row gutter={[16, 16]}>
                      {JSON.parse(localStorage.getItem("authUserV0")).roleId ===
                        1 && (
                        <Col lg={8}>
                          <Form.Item label="Customer" name="customerId">
                            <Select
                              showSearch
                              placeholder="Select a company"
                              options={customers}
                              style={{ minWidth: "150px" }}
                              onChange={() => getDatas()}
                            />
                          </Form.Item>
                        </Col>
                      )}

                      <Col lg={8}>
                        <Form.Item label="Start / End Date" name="dates">
                          <RangePicker
                            style={{ width: "100%" }}
                            format={"DD/MM/YYYY"}
                            allowClear
                            onChange={val => {
                              if (val) {
                                setValue(val)
                                if (val[0] && val[1]) {
                                  getDatas()
                                }
                              } else {
                                setValue(null)
                              }
                            }}
                          />
                        </Form.Item>
                      </Col>
                      <Col
                        lg={4}
                        className="d-flex align-items-end justify-content-start gap-2"
                      >
                        <Button
                          type="button"
                          className="btn btn-light d-flex align-items-center justify-content-center"
                          icon={<ClearOutlined />}
                          style={{
                            border: "1px solid #f94040",
                            background: "#f94040",
                            color: "white",
                            width: "100%",
                          }}
                          onClick={() => {
                            formSearch.resetFields()
                            setValue(null)
                            getDatas()
                            formSearch.setFieldsValue({
                              customerId: "",
                            })
                          }}
                        >
                          Clear
                        </Button>
                        <Button
                          type="button"
                          className="btn btn-primary d-flex align-items-center justify-content-center "
                          style={{
                            border: "1px solid #556ee6",
                            width: "100%",
                          }}
                          icon={<SearchOutlined />}
                          onClick={() => getDatas()}
                        >
                          Search
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                </Col>
              </Row>
            </Card>
          </Col>
          <Col lg={8}>
            <Card hoverable className="mt-3" style={{ opacity: "0.4" }}>
              <Divider orientation="left">Financial Balance</Divider>
              <Row gutter={[16, 16]}>
                <Col lg={24}>
                  <div className="ui">
                    <div className="ui_box">
                      <div className="ui_box__inner">
                        <h2>Total Balance</h2>
                        <div className="stat">
                          <h1>
                            {" "}
                            <span>${"5000"}</span>{" "}
                          </h1>
                        </div>
                        <div className="progress">
                          <div
                            className="progress_bar"
                            style={{ width: "99%" }}
                          ></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col lg={24}>
                  <div className="ui">
                    <div className="ui_box ui_box_red">
                      <div className="ui_box__inner">
                        <div
                          className="start"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <div className="stat">
                            <h2>Total Expenditure</h2>
                            <h1>
                              {" "}
                              <span>${"2100"}</span>{" "}
                            </h1>
                          </div>
                          <div className="stat">
                            <h2>Total Available Balance</h2>
                            <h1>
                              {" "}
                              <span>${"2900"}</span>{" "}
                            </h1>
                          </div>
                        </div>
                        <div className="progress">
                          <div
                            className="progress_bar"
                            style={{
                              width: `${(Math.round(2500) * 100) / 5000}%`,
                              background: "#ffff00",
                            }}
                          ></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
        <Card className="mt-3">
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="mt-3">
            <Col span={24}>
              <Card hoverable>
                <Form form={companyForm} layout="vertical" className="mb-3">
                  <Row gutter={[16, 16]}>
                    {JSON.parse(localStorage.getItem("authUserV0")).roleId ===
                      1 && (
                      <Col lg={5}>
                        <Form.Item label="Customer" name="customer_id">
                          <Select
                            showSearch
                            placeholder="Select a customer"
                            options={customersV2}
                            style={{ minWidth: "150px" }}
                            onChange={() => getDetailCompany()}
                          />
                        </Form.Item>
                      </Col>
                    )}
                  </Row>
                </Form>
                <Table
                  title={() => "Total Count"}
                  bordered
                  size="small"
                  columns={columnsCount}
                  dataSource={data}
                  loading={loading}
                  rowKey={record => record.id}
                />
              </Card>
            </Col>
          </Row>
        </Card>

        <Drawer
          title={`Record Name: ${drawer?.data?.recordName}`}
          placement="right"
          size="large"
          onClose={() => setDrawer({ show: false, data: null })}
          open={drawer.show}
        >
          <Table
            bordered
            size="small"
            columns={columnsDetail}
            dataSource={detailData}
            rowKey={record => record.id}
          />
        </Drawer>
      </div>
    </React.Fragment>
  )
}

export default DeriFinancial
