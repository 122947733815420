import React, { useEffect, useState, useRef } from "react"
import { Table, Button, Tooltip, Collapse } from "antd"
import { getColumnSearchProps } from "utils/filter"
import UpsertModal from "./modals/upsert"
import * as Http from "utils/http.helper"
import * as moment from "moment"
import toastr from "toastr"
import "toastr/build/toastr.min.css"
const { Panel } = Collapse

const MainCompany = () => {
  document.title = "Company | EASY JET CONNECT"
  const searchInput = useRef(null)
  const [loading, setLoading] = useState(true)
  const [mainCompany, setMainCompany] = useState([])
  const [upsertModal, setUpsertModal] = useState({ show: false, data: null })
  const [userInfo, setUserInfo] = useState(null)
  const [searchText, setSearchText] = useState("")

  useEffect(() => {
    const info = JSON.parse(localStorage.getItem("authUserV0"))
    setUserInfo(info)
  }, [])

  const getData = () => {
    setLoading(true)
    Http.get("api/companies/GetMainCompanies")
      .then(res => {
        if (res?.success) {
          setLoading(false)
          setMainCompany(res.data.reverse())
        }
      })
      .catch(function (error) {
        setLoading(false)
        toastr.error(error.response.message)
      })
  }
  useEffect(() => {
    getData()
  }, [])
  const columns = [
    {
      title: "Company Name",
      dataIndex: "name",
      key: "name",
      ...getColumnSearchProps(
        "name",
        "Filter by company name",
        searchInput,
        searchText,
        setSearchText
      ),
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: "Description",
      dataIndex: "descriptio",
      key: "descriptio",
    },

    {
      title: "Create Date",
      dataIndex: "addDate",
      key: "addDate",
      render: value => {
        return value ? moment(new Date(value)).format("DD MMM YYYY") : ""
      },
    },

    {
      title: " ",
      key: "action",
      align: "center",
      width: 120,
      render: (_, record) => (
        <Tooltip placement="top" title="Edit Main Company">
          <Button
            type="dashed"
            className="btn btn-primary d-flex align-items-center justify-content-center m-auto"
            icon={<i className="mdi mdi-pencil" />}
            onClick={() => {
              setUpsertModal({ show: true, data: record })
            }}
          />
        </Tooltip>
      ),
    },
  ]
  return (
    <React.Fragment>
      {upsertModal && (
        <UpsertModal
          upsertModal={upsertModal}
          setUpsertModal={setUpsertModal}
          getData={getData}
          setLoading={setLoading}
        />
      )}

      <div className="page-content mb-3">
        <Collapse activeKey={["1"]} expandIcon={() => false}>
          <Panel
            key="1"
            header={
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                Company List
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "end",
                    columnGap: "15px",
                  }}
                >
                  <Button
                    className="btn btn-primary d-flex align-items-center justify-content-end"
                    onClick={() => setUpsertModal({ show: true, data: null })}
                    icon={
                      <i className="bx bx-plus font-size-16 align-middle"></i>
                    }
                  >
                    Add New Company
                  </Button>
                </div>
              </div>
            }
          >
            <Table
              title={false}
              columns={columns}
              dataSource={mainCompany}
              loading={loading}
              bordered
            />
          </Panel>
        </Collapse>
      </div>
    </React.Fragment>
  )
}

export default MainCompany
