import React, { useState, useEffect } from "react"
import { Container, Row, Col } from "reactstrap"
import { Table, Tag, Button, Card, Tooltip, Form, Input, Select } from "antd"
import * as Http from "utils/http.helper"
import {
  SearchOutlined,
  ClearOutlined,
  UploadOutlined,
} from "@ant-design/icons"
import toastr from "toastr"
import * as moment from "moment"
import TemplateDesignModal from "../Modals/template_design"
import VariantModal from "../Modals/variant"
import Breadcrumbs from "components/Common/Breadcrumb"
import { BrowserRouter as Router, useNavigate } from "react-router-dom"
import ComingSoonPage from "components/comingSoon"

const css = `

#order-list td{
  vertical-align: middle;
}
tr{ cursor:pointer }
 .multipleRow, .multipleRow .ant-table-cell-fix-right{
  background:#c2dafe!important;
}
.multipleRow td:first-child
{
  font-weight:bold
}
.ant-table-row-level-1 .ant-table-cell:first-child,
.ant-table-row-level-2 .ant-table-celll:first-child,
.ant-table-row-level-3 .ant-table-celll:first-child,
.ant-table-row-level-4 .ant-table-celll:first-child,
.ant-table-row-level-5 .ant-table-celll:first-child,
.ant-table-row-level-6 .ant-table-celll:first-child,
.ant-table-row-level-7 .ant-table-celll:first-child,
.ant-table-row-level-8 .ant-table-celll:first-child,
.ant-table-row-level-9 .ant-table-celll:first-child,
.ant-table-row-level-10 .ant-table-celll:first-child{
  font-weight:bold
}
.ant-table-cell-row-hover,
.ant-table-cell-row-hover.ant-table-cell-fix-right{
  background:#dddddd!important;
}
.ant-table-cell.ant-table-cell-with-append .ant-table-row-expand-icon.ant-table-row-expand-icon-spaced{
  background:red
}
.multipleRow:hover .ant-table-cell-row-hover{
  background:#c2dafe!important;
}
@page  
{ 
    size: auto;  
    margin: 25mm 5mm 25mm 5mm;  
}`

const EngravingFaultyOrderList = () => {
  document.title = "Waiting For Design List | EASY JET CONNECT"
  const navigate = useNavigate()
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const [orderStatus, setOrderStatus] = useState([
    {
      value: 10,
      label: "New Order",
    },
    {
      value: 20,
      label: "Production Ready",
    },
    {
      value: 30,
      label: "Production",
    },
    {
      value: 40,
      label: "Pdf Printed",
    },
    {
      value: 50,
      label: "Print",
    },
    {
      value: 60,
      label: "Tamamlandı",
    },
    {
      value: 70,
      label: "Design Bekliyor",
    },
  ])
  const [tableParams, setTableParams] = useState({
    current: 0,
    pageSize: 0,
    total: 0,
  })
  const [engravingList, setEngravingList] = useState([])
  const [tempDesignModal, setTempDesignModal] = useState({
    show: false,
    data: null,
    customerId: null,
  })

  const [variantModal, setVariantModal] = useState({
    show: false,
    data: null,
  })

  useEffect(() => {
    getTableData()
  }, [])

  const getTableData = pagination => {
    setLoading(true)
    const { orderId } = form.getFieldValue()
    Http.get(
      `api/orders/GetWaitingForDesignOrders?pageNumber=${
        pagination?.current ? pagination.current : 1
      }&limit=${pagination?.pageSize ? pagination.pageSize : 10}&categoryId=3${
        orderId ? "&search=" + orderId : ""
      }`
    )
      .then(res => {
        if (res?.success) {
          if (res?.data?.data?.orders.length > 0) {
            res?.data?.data?.orders.map(item => {
              item.key = item.id
              item?.children?.length === 0 && delete item.children
            })
            setEngravingList(res?.data?.data?.orders)
            setTableParams({
              current: pagination?.current ? pagination.current : 1,
              pageSize: pagination?.pageSize ? pagination.pageSize : 10,
              total:
                res?.data?.totalItems !== 0
                  ? res?.data?.totalItems
                  : tableParams.total,
            })
          } else {
            setEngravingList([])
          }
        } else {
          setTableParams({
            current: 1,
            pageSize: 10,
            total: 0,
          })
        }
        setLoading(false)
      })
      .catch(function (error) {
        setLoading(false)
        toastr.error(error)
      })
  }

  const columns = [
    {
      title: "Sku",
      dataIndex: "sku",
      width: 150,
    },

    {
      title: "Order Id",
      dataIndex: "orderId",
    },
    {
      title: "Customer Name",
      dataIndex: "customerName",
      hidden: JSON.parse(localStorage.getItem("authUserV0"))?.roleId === 2,
    },
    {
      title: "Product Name",
      dataIndex: "productName",
    },
    {
      title: "Quant.",
      dataIndex: "quantity",
      width: 80,
    },
    {
      title: "Price",
      dataIndex: "price",
    },
    {
      title: "Variant",
      dataIndex: "variant",
      width: 130,
      render: (_, row) => {
        return (
          <Tooltip placement="top" title={row.variant}>
            <div className="tooltip_table">
              {row.variant.substring(0, 12)}...
            </div>
          </Tooltip>
        )
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      width: "150px",
      render: (_, row) => {
        return (
          <Tag color={row.status === 10 ? "red" : "green"} key={row.id}>
            {orderStatus.find(x => x.value === row.status)?.label}
          </Tag>
        )
      },
    },
    {
      title: "Name Surname",
      dataIndex: "nameSurname",
      render: (_, row) => {
        return row.shipmentDetail.nameSurname
      },
    },
    {
      title: "Sale Date",
      dataIndex: "saleDate",
      key: "saleDate",
      render: value => {
        return moment(new Date(value)).format("DD MMM YYYY")
      },
    },
    {
      title: " ",
      key: "action",
      hidden: JSON.parse(localStorage.getItem("authUserV0"))?.roleId === 1,
      render: (_, record) => {
        return (
          <Tooltip placement="top" title="Upload Image">
            <Button
              className="btn btn_tertiary d-flex align-items-center justify-content-center  m-auto"
              icon={<UploadOutlined />}
              onClick={() => {
                navigate(
                  `/engraving-design-upload?sku=${record.sku}&orderId=${
                    record.id
                  }&orderNo=${record.orderId}&waitingList=${true}`
                )
              }}
              style={{ marginRight: 5 }}
            />
          </Tooltip>
        )
      },
    },
  ].filter(item => !item.hidden)

  const handleTableChange = pagination => {
    setLoading(true)
    setTableParams({
      current: pagination.current,
      pageSize: pagination.pageSize,
      total: tableParams.total,
    })
    if (
      tableParams.current !== pagination.current ||
      tableParams.pageSize !== pagination.pageSize
    ) {
      getTableData({
        current: pagination.current,
        pageSize: pagination.pageSize,
      })
    }
  }

  return (
    <React.Fragment>
      <ComingSoonPage date="Jul 28 2024 23:59:59 GMT+0300" />
      {/* <style>{css}</style>
      <div className="page-content mb-3">
        {tempDesignModal.show && (
          <TemplateDesignModal
            tempDesignModal={tempDesignModal}
            setTempDesignModal={setTempDesignModal}
          />
        )}
        {variantModal.show && (
          <VariantModal
            variantModal={variantModal}
            setVariantModal={setVariantModal}
            setLoading={setLoading}
            getTableData={getTableData}
          />
        )}
        <Container fluid>
          <Breadcrumbs title="Engraving Waiting For Design List" />
          <Card className="mt-3">
            <Form form={form} layout="vertical">
              <Row>
                <Col lg={2}>
                  <Form.Item label="Order Id" name="orderId">
                    <Input onPressEnter={() => getTableData(null)} allowClear />
                  </Form.Item>
                </Col>
                <Col
                  span={3}
                  className="d-flex align-items-center justify-content-start gap-2"
                >
                  <Button
                    type="button"
                    className="btn btn-primary d-flex align-items-center justify-content-start mt-1"
                    icon={<SearchOutlined />}
                    onClick={() => {
                      form
                        .validateFields()
                        .then(() => {
                          getTableData(null)
                        })
                        .catch(err => {
                          setLoading(false)
                        })
                    }}
                  >
                    Search
                  </Button>
                  <Button
                    type="button"
                    className="btn btn-light d-flex align-items-center justify-content-start mt-1"
                    icon={<ClearOutlined />}
                    onClick={() => {
                      form.setFieldsValue({
                        orderId: "",
                      })
                      setEngravingList([])
                    }}
                  >
                    Clear
                  </Button>
                </Col>
              </Row>
            </Form>
            <Table
              bordered
              size="small"
              columns={columns}
              dataSource={engravingList}
              loading={loading}
              pagination={tableParams}
              onChange={handleTableChange}
              rowKey={record => record.id}
              rowClassName={record =>
                record.children?.length > 0 ? "multipleRow" : ""
              }
            />
          </Card>
        </Container>
      </div> */}
    </React.Fragment>
  )
}

export default EngravingFaultyOrderList
