import React, { useState, useEffect } from "react"
import Breadcrumbs from "components/Common/Breadcrumb"
import {
  Row,
  Col,
  InputNumber,
  Card,
  Button,
  Spin,
  Form,
  Select,
  Collapse,
  Descriptions,
} from "antd"
import Sounds from "utils/sounds"
import * as Http from "utils/http.helper"
import toastr from "toastr"
import "toastr/build/toastr.min.css"
const { Option } = Select
const { Panel } = Collapse
const layout = {
  labelCol: {
    span: 6,
  },
  wrapperCol: {
    span: 18,
  },
}
const UVDTFManagement = () => {
  document.title = "UVDTF Price Management | EASY JET CONNECT"
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const [update, setUpdate] = useState(false)
  const [customers, setCustomers] = useState([])
  const [data, setData] = useState([])
  const getAllCustomers = () => {
    Http.get(`api/companies/getAllCustomers?categoryId=4`)
      .then(res => {
        if (res?.success) {
          setCustomers(
            res.data.map(item => {
              return { value: item.id, label: item.name }
            })
          )
        }
      })
      .catch(function (error) {
        toastr.error(error)
      })
  }

  useEffect(() => {
    getAllCustomers()
  }, [])

  const getData = () => {
    Http.get(`api/Uvdtf/GetUvdtfCustomerPriceCoefficient`)
      .then(res => {
        setData(res ? res : [])
      })
      .catch(function (error) {
        toastr.error(error)
      })
  }

  useEffect(() => {
    getData()
  }, [])

  const handleSave = () => {
    setLoading(true)
    const values = form.getFieldsValue()

    let obj = {
      companyId: values.companyId,
      coefficient: values.cost,
    }
    Http.post("api/Uvdtf/SetUvdtfCustomerPriceCoefficient", obj)
      .then(res => {
        if (res.companyId) {
          Sounds.success()
          setLoading(false)
          getData()
          setUpdate(false)
          toastr.success(`İşlem Başarılı.`)
        }
      })
      .catch(function (error) {
        Sounds.error()
        setLoading(false)
        toastr.error(error?.response?.data?.Message)
      })
  }
  return (
    <React.Fragment>
      <div className="page-content mb-3">
        <Spin spinning={loading}>
          <Collapse activeKey={["1"]} expandIcon={() => false}>
            <Panel header="UVDTF Price Management" key="1">
              <Row
                gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Col span={8}>
                  <Card>
                    <Form {...layout} form={form} style={{ marginTop: 30 }}>
                      <Form.Item
                        label="Customer"
                        name="companyId"
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <Select
                          allowClear
                          placeholder="Select a company"
                          style={{ minWidth: "150px" }}
                        >
                          {customers.map(item => (
                            <Option value={item.value} key={item.value}>
                              {item.label}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                      <Form.Item
                        name="cost"
                        label="Cost for 1 inch"
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <InputNumber min={0.1} style={{ width: "100%" }} />
                      </Form.Item>
                      <Form.Item
                        style={{ display: "flex", justifyContent: "end" }}
                      >
                        <Button
                          type="button"
                          className="btn btn-primary d-flex align-items-center"
                          onClick={() => {
                            form
                              .validateFields()
                              .then(() => {
                                handleSave()
                              })
                              .catch(err => {})
                          }}
                        >
                          {update ? "Update" : "Save"}
                        </Button>
                      </Form.Item>
                    </Form>
                  </Card>
                </Col>
                <Col span={16}>
                  <Card>
                    <Descriptions
                      bordered
                      title="Cost of 1 inch owned by customer"
                      column={3}
                    >
                      {data.length > 0 &&
                        data.map(item => {
                          return (
                            <Descriptions.Item
                              label={item.companyName}
                              key={item.id}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "space-between",
                                  columnGap: 10,
                                }}
                              >
                                {item.coefficient}
                                <Button
                                  type="dashed"
                                  className="d-flex align-items-center"
                                  onClick={() => {
                                    form.setFieldsValue({
                                      companyId: item.companyId,
                                      cost: item.coefficient,
                                    })
                                    setUpdate(true)
                                    toastr.info(
                                      `Soldaki form alanından yeni değer girerek güncelleme işlemi yapabilirsiniz!`
                                    )
                                  }}
                                >
                                  Edit
                                </Button>
                              </div>
                            </Descriptions.Item>
                          )
                        })}
                    </Descriptions>
                  </Card>
                </Col>
              </Row>
            </Panel>
          </Collapse>
        </Spin>
      </div>
    </React.Fragment>
  )
}

export default UVDTFManagement
