import React from "react"
import ComingSoon from "react-coming-soon"

const ComingSoonPage = props => {
  return (
    <ComingSoon
      title="We are left with few things"
      subtitle="See you in"
      image="https://react-coming-soon.maksv.me/default-image.jpeg"
      bgColor="#2A2A29"
      textColor="#ffffff"
      date={props.date}
      illustration="web-development"
    />
  )
}

export default ComingSoonPage

// Monday (Mon) – Pazartesi.
// Tuesday (Tue) – Salı
// Wednesday (Wed) – Çarşamba.
// Thursday (Thu) – Perşembe.
// Friday (Fri) – Cuma.
// Saturday (Sat) – Cumartesi.
// Sunday (Sun) – Pazar.

// January (Jan): Ocak.
// February (Feb): Şubat.
// March (Mar): Mart.
// April (Apr): Nisan.
// May (May): Mayıs.
// June (Jun): Haziran.
// July (Jul): Temmuz.
// August (Aug): Ağustos.
