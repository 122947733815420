import React, { useState, useEffect } from "react"
import { Modal, Form, Input, Select, Row, Col } from "antd"
import * as Http from "utils/http.helper"
import toastr from "toastr"
import "toastr/build/toastr.min.css"
const UpsertModal = ({ upsertModal, setUpsertModal, getData, setLoading }) => {
  const [form] = Form.useForm()
  const [categories, setCategories] = useState([])

  useEffect(() => {
    if (upsertModal.data?.id) {
      form.setFieldsValue({
        customerName: upsertModal.data?.name,
        description: upsertModal.data?.description,
        categories: upsertModal.data?.categories,
      })
    }
  }, [upsertModal])

  const getMainCategories = () => {
    Http.get("api/categories/getMainCategories")
      .then(res => {
        if (res) {
          let arr = []
          let userCategories = JSON.parse(
            localStorage.getItem("authUserV0")
          ).userCategories
          res.map(item => {
            let find = userCategories.find(x => x.categoryId === item.id)
            if (find) {
              arr.push({
                value: item.id,
                label: item.name,
              })
            }
          })
          setCategories(arr)
        }
      })
      .catch(function (error) {
        toastr.error(error.response.Message)
      })
  }
  useEffect(() => {
    getMainCategories()
  }, [])

  const onSave = () => {
    setLoading(true)
    const values = form.getFieldsValue()
    const obj = {
      companyId: upsertModal.data?.id,
      name: values.customerName,
      description: values.description,
      categories: values.categories,
    }
    if (upsertModal.data?.id) {
      Http.post("api/companies/UpdateCompany", obj)
        .then(res => {
          if (res.success) {
            toastr.success(`${values.customerName} company has been update.`)
            getData()
            setUpsertModal({ show: false, data: null })
          } else {
            toastr.error(`${values.customerName} company update failed`)
          }
        })
        .catch(function (error) {
          setLoading(false)
        })
    } else {
      delete obj.companyId
      Http.post("api/companies/AddCompany", obj)
        .then(res => {
          if (res.success) {
            toastr.success(`${values.customerName} company has been create.`)
            getData()
            setUpsertModal({ show: false, data: null })
          } else {
            toastr.error(`${values.customerName} company create failed`)
          }
        })
        .catch(function (error) {
          setLoading(false)
        })
    }
  }

  return (
    <Modal
      maskClosable={false}
      title={
        upsertModal.data?.id ? (
          <span style={{ display: "flex", alignItems: "center" }}>
            Edit Customer
            <span
              style={{
                opacity: "0.6",
                fontSize: "13px",
                marginLeft: "8px",
              }}
            >
              [ {upsertModal.data?.name} ]
            </span>
          </span>
        ) : (
          "Add Customer"
        )
      }
      open={upsertModal.show}
      onOk={onSave}
      okText="Save"
      onCancel={() => {
        form.resetFields()
        setUpsertModal({ show: false, data: null })
      }}
    >
      <Form
        form={form}
        layout="vertical"
        onSubmit={e => {
          e.preventDefault()
          validation.handleSubmit()
          return false
        }}
      >
        <Row gutter={[16, 16]}>
          <Col xs={24}>
            <Form.Item
              label="Customer Name"
              name="customerName"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col lg={24}>
            <Form.Item
              label="Categories"
              name="categories"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Select
                mode="multiple"
                style={{ width: "100%" }}
                options={categories}
              />
            </Form.Item>
          </Col>
          <Col lg={24}>
            <Form.Item label="Description" name="description">
              <Input />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  )
}

export default UpsertModal
