import React, { useState, useEffect, useRef } from "react"
import { Table, Button, Tooltip, Result, Collapse } from "antd"
import * as Http from "utils/http.helper"
import UpsertModal from "./modals/upsert"
import { getColumnSearchProps } from "utils/filter"
import toastr from "toastr"
import "toastr/build/toastr.min.css"
const { Panel } = Collapse
const StyleManagement = () => {
  document.title = "Style Management | EASY JET CONNECT"
  const searchInput = useRef(null)
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState([])
  const [upsertModal, setUpsertModal] = useState({
    show: false,
    data: null,
  })
  const [searchText, setSearchText] = useState("")

  const getData = () => {
    setLoading(true)
    Http.get(`api/orders/GetAllProperties`)
      .then(res => {
        if (res?.success) {
          setData(
            res.data.map(item => {
              return { id: item.styleId, name: item.styleName }
            })
          )
          setLoading(false)
        }
      })
      .catch(function (error) {
        setLoading(false)
        toastr.error(error.response.data.Message)
      })
  }

  useEffect(() => {
    getData()
  }, [])

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      ...getColumnSearchProps(
        "name",
        "Filter by color style name",
        searchInput,
        searchText,
        setSearchText
      ),
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: " ",
      key: "action",
      align: "center",
      width: 120,
      render: (_, record) => (
        <div className="d-flex align-items-center justify-content-center gap-1">
          {/* <Tooltip placement="top" title="Delete">
            <Button
              className="btn btn-danger d-flex align-items-center justify-content-center"
              icon={<i className="mdi mdi-delete" />}
              onClick={() => {
                alert("Delete işlemi eklenecek.")
              }}
              style={{ marginRight: 5 }}
            />
          </Tooltip> */}
          <Tooltip placement="top" title="Edit Color">
            <Button
              type="dashed"
              className="btn btn-primary d-flex align-items-center justify-content-center m-auto"
              icon={<i className="mdi mdi-pencil" />}
              onClick={() => {
                setUpsertModal({ show: true, data: record })
              }}
            />
          </Tooltip>
        </div>
      ),
    },
  ]

  let locale = {
    emptyText: (
      <Result
        title="No data"
        subTitle="If you haven't selected any company or added financial data yet, you can select and list a company or create a new financial value."
      />
    ),
  }

  return (
    <React.Fragment>
      {upsertModal.show && (
        <UpsertModal
          upsertModal={upsertModal}
          setUpsertModal={setUpsertModal}
          loading={loading}
          setLoading={setLoading}
          getData={getData}
        />
      )}
      <div className="page-content mb-3">
        <Collapse activeKey={["1"]} expandIcon={() => false}>
          <Panel
            key="1"
            header={
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                Style Managemenet
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "end",
                    columnGap: "15px",
                  }}
                >
                  <div>
                    <Button
                      className="btn btn-primary d-flex align-items-center justify-content-end"
                      onClick={() => setUpsertModal({ show: true, data: null })}
                      icon={
                        <i className="bx bx-plus font-size-16 align-middle"></i>
                      }
                    >
                      Add Style
                    </Button>
                  </div>
                </div>
              </div>
            }
          >
            <Table
              size="small"
              bordered
              columns={columns}
              dataSource={data}
              className="table table-centered"
              loading={loading}
              locale={locale}
            />{" "}
          </Panel>
        </Collapse>
      </div>
    </React.Fragment>
  )
}

export default StyleManagement
