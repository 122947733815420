import React, { useEffect } from "react"
import { Form, Input, Select, Row, Modal, Col, Tag, Space, Button } from "antd"
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons"
import * as Http from "utils/http.helper"
import toastr from "toastr"
import "toastr/build/toastr.min.css"

const css = `.ant-tag{
  white-space:normal!important;
  padding: 0 15px
}
.ant-space-item:first-child{
  width:100%
}
`
const UpsertModal = ({
  upsertModal,
  setUpsertModal,
  loading,
  setLoading,
  getData,
  styleList,
}) => {
  const [form] = Form.useForm()
  useEffect(() => {
    if (upsertModal.data?.id) {
      form.setFieldsValue({
        sizeName: upsertModal?.data.name,
        styleId: upsertModal?.data?.styleId,
      })
    }
  }, [upsertModal])

  const onFinish = values => {
    setLoading(true)
    setLoading(false)
    if (upsertModal.data?.id) {
      const obj = {
        sizeId: upsertModal?.data?.id,
        sizeName: values.sizeName,
        styleId: values.styleId,
      }
      Http.post("api/sizes/update", obj)
        .then(res => {
          if (res.success) {
            toastr.success(
              `${upsertModal?.data?.name} isimli size güncellenmiştir.`
            )
            getData()
            setUpsertModal({ show: false, data: null })
          } else {
            setLoading(false)
            toastr.error(
              `${upsertModal?.data?.name} isimli size güncelleme işlemi başarısız.`
            )
          }
        })
        .catch(function (error) {
          setLoading(false)
          toastr.error(error.response.data.title)
        })
    } else {
      const obj = {
        styleId: values.styleId,
        sizeNameList: [values.sizeName],
      }
      values.sizeList &&
        values.sizeList.length > 0 &&
        values.sizeList.map(item => obj.sizeNameList.push(item.sizeName))
      Http.post("api/sizes/add", obj)
        .then(res => {
          if (res.success) {
            toastr.success("Adding size value was successful")
            getData()
            setUpsertModal({ show: false, data: null })
            setLoading(false)
          } else {
            setLoading(false)
            toastr.error(res?.Message)
          }
        })
        .catch(function (error) {
          setLoading(false)
          toastr.error(error.response.data.title)
        })
    }
  }

  return (
    <Modal
      title={upsertModal.data?.id ? "Size Update" : "Add New Size"}
      centered
      maskClosable={false}
      open={upsertModal.show}
      cancelText="Close"
      okText={upsertModal.data?.id ? "Update" : "Save"}
      onOk={() => {
        form
          .validateFields()
          .then(val => {
            onFinish(val)
          })
          .catch(err => {})
      }}
      onCancel={() => setUpsertModal({ show: false, data: null })}
      width={600}
      confirmLoading={loading}
    >
      <Row style={{ marginTop: 25 }}>
        <style>{css}</style>
        <Col span={24}>
          <Tag color="volcano">
            * Size Name alanları yüklenebilecek farklı csv dosyalarındaki
            Variations alanında yazan size ile birebir eşleşmesi (Büyük, Küçük
            Harf) gerekmektedir. Örneğin; Bir csv dosyasında{" "}
            <span style={{ color: "black", fontWeight: "bold" }}>
              Comfort Colors XXL
            </span>{" "}
            var diğerinde{" "}
            <span style={{ color: "black", fontWeight: "bold" }}>
              Comfort Colors 2XL
            </span>{" "}
            var ise sistemede ikisinin de tanımlı olması gerekmekte ve ikisi
            için de price tanımı yapılmalıdır.
          </Tag>
        </Col>
        <Col span={24} style={{ marginTop: 25 }}>
          <Form
            layout="vertical"
            form={form}
            name="dynamic_form_complex"
            autoComplete="off"
          >
            <Form.Item
              name="styleId"
              label="Style"
              rules={[
                {
                  required: true,
                  message: "Style alanı zorunludur.",
                },
              ]}
            >
              <Select
                options={styleList || []}
                disabled={upsertModal?.data?.id}
                placeholder="Style seçiniz."
                showSearch
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
              />
            </Form.Item>
            <Form.Item
              name="sizeName"
              label="Size Name"
              rules={[
                {
                  required: true,
                  message: "Size Name alanı zorunludur.",
                },
              ]}
            >
              <Input placeholder="Lütfen size isimlendirmenizi giriniz..." />
            </Form.Item>
            {!upsertModal?.data?.id && (
              <Form.List name="sizeList" style={{ width: "100%" }}>
                {(fields, { add, remove }) => (
                  <>
                    {fields.map(({ key, name, ...restField }) => (
                      <Space
                        key={key}
                        style={{
                          display: "flex",
                          marginBottom: 5,
                          alignItems: "center",
                          justifyContent: "start",
                          width: "100%",
                        }}
                        align="baseline"
                      >
                        <Form.Item
                          {...restField}
                          name={[name, "sizeName"]}
                          label={`Size Name`}
                          rules={[
                            {
                              required: true,
                              message: "Size Name alanı zorunludur.",
                            },
                          ]}
                        >
                          <Input
                            placeholder="Lütfen size isimlendirmenizi giriniz..."
                            style={{ width: "100%" }}
                          />
                        </Form.Item>
                        <MinusCircleOutlined
                          onClick={() => remove(name)}
                          style={{ marginTop: 12, color: "red" }}
                        />
                      </Space>
                    ))}
                    <Form.Item>
                      <Button
                        type="dashed"
                        onClick={() => add()}
                        block
                        icon={<PlusOutlined />}
                      >
                        Add Size
                      </Button>
                    </Form.Item>
                  </>
                )}
              </Form.List>
            )}
          </Form>
        </Col>
      </Row>
    </Modal>
  )
}

export default UpsertModal
const { Search } = Input
