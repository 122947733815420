import React, { useState, useEffect } from "react"
import {
  Form,
  Input,
  Select,
  Row,
  Modal,
  Col,
  ColorPicker,
  Space,
  Button,
  Tag,
} from "antd"
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons"
import * as Http from "utils/http.helper"
import toastr from "toastr"
import "toastr/build/toastr.min.css"

const css = `.ant-tag{
  white-space:normal!important;
  padding: 0 15px
}
.ant-space-item:first-child{
  width:100%
}
`
const UpsertModal = ({
  upsertModal,
  setUpsertModal,
  loading,
  setLoading,
  getData,
  options,
}) => {
  const [form] = Form.useForm()
  const [hexCodeValue, sethexCodeValue] = useState(null)
  useEffect(() => {
    if (upsertModal.data?.id) {
      form.setFieldsValue({
        colorHexCode: upsertModal?.data.hexCode,
        colorName: upsertModal?.data.name,
        styleId: upsertModal?.data?.styleId,
      })
      sethexCodeValue(upsertModal?.data.hexCode)
    }
  }, [upsertModal])

  const onFinish = values => {
    setLoading(true)

    if (upsertModal.data?.id) {
      const obj = {
        colorId: upsertModal?.data?.id,
        colorName: values.colorName,
        hexCode: values.hexCode ? values.hexCode.toHexString() : "#000000",
      }
      Http.post("api/colors/update", obj)
        .then(res => {
          if (res.success) {
            toastr.success(
              `${upsertModal?.data?.name} isimli renk güncellenmiştir.`
            )
            getData()
            setUpsertModal({ show: false, data: null })
          } else {
            setLoading(false)
            toastr.error(
              `${upsertModal?.data?.name} isimli renk güncelleme işlemi başarısız.`
            )
          }
        })
        .catch(function (error) {
          setLoading(false)
        })
    } else {
      const obj = {
        styleId: values?.styleId,
        styleColorList: [
          {
            colorName: values.colorName,
            hexCode: values.hexCode ? values.hexCode.toHexString() : "#000000",
          },
        ],
      }
      values.nameAndColorList?.length > 0 &&
        values.nameAndColorList.map(item => {
          obj.styleColorList.push({
            colorName: item.colorName,
            hexCode: item.hexCode ? item.hexCode.toHexString() : "#000000",
          })
        })

      Http.post("api/colors/add", obj)
        .then(res => {
          if (res.success) {
            toastr.success("Renk ekleme işlemi başarılı.")
            getData()
            setUpsertModal({ show: false, data: null })
          } else {
            setLoading(false)
            toastr.error("Renk ekleme işlemi başarısız.")
          }
        })
        .catch(function (error) {
          setLoading(false)
        })
    }
  }
  return (
    <Modal
      title={upsertModal.data?.id ? "Color Update" : "Add New Color"}
      centered
      maskClosable={false}
      open={upsertModal.show}
      cancelText="Close"
      okText={upsertModal.data?.id ? "Update" : "Save"}
      onOk={() => {
        form
          .validateFields()
          .then(val => {
            onFinish(val)
          })
          .catch(err => {})
      }}
      onCancel={() => setUpsertModal({ show: false, data: null })}
      width={600}
      confirmLoading={loading}
    >
      <style>{css}</style>
      <Row style={{ marginTop: 25 }}>
        <Col span={24}>
          <Tag color="volcano">
            * Color Name alanları yüklenebilecek farklı csv dosyalarındaki
            Variations alanında yazan color ile birebir eşleşmesi (Büyük, Küçük
            Harf) gerekmektedir. Örneğin; Bir csv dosyasında{" "}
            <span style={{ color: "black", fontWeight: "bold" }}>
              Heather MidnightNavy
            </span>{" "}
            var diğerinde{" "}
            <span style={{ color: "black", fontWeight: "bold" }}>
              heather midnightnavy
            </span>{" "}
            var ise sistemede ikisinin de tanımlı olması gerekmektedir.
          </Tag>
        </Col>
        <Col span={24} style={{ marginTop: 25 }}>
          <Form layout="vertical" form={form}>
            <Form.Item
              name="styleId"
              label="Style"
              rules={[
                {
                  required: true,
                  message: "Style alanı zorunludur.",
                },
              ]}
            >
              <Select
                options={options?.styles}
                disabled={upsertModal?.data?.id}
                showSearch
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
              />
            </Form.Item>
            <Space
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "start",
              }}
              align="baseline"
            >
              <Form.Item
                name={"colorName"}
                label={`Color Name`}
                rules={[{ required: true, message: "Missing last name" }]}
              >
                <Input />
              </Form.Item>
              <Form.Item name={"hexCode"} label={`HexCode`}>
                <ColorPicker defaultValue={hexCodeValue} showText />
              </Form.Item>

              <MinusCircleOutlined style={{ marginTop: 12, opacity: 0 }} />
            </Space>

            {!upsertModal?.data?.id && (
              <Form.List name="nameAndColorList">
                {(fields, { add, remove }) => (
                  <>
                    {fields.map(({ key, name, ...restField }) => (
                      <Space
                        key={key}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "start",
                        }}
                        align="baseline"
                      >
                        <Form.Item
                          {...restField}
                          name={[name, "colorName"]}
                          label={`Color Name`}
                          rules={[
                            { required: true, message: "Missing last name" },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                        <Form.Item
                          {...restField}
                          name={[name, "hexCode"]}
                          label={` HexCode`}
                        >
                          <ColorPicker defaultValue={"#000000"} showText />
                        </Form.Item>

                        <MinusCircleOutlined
                          onClick={() => remove(name)}
                          style={{ marginTop: 12, color: "red" }}
                        />
                      </Space>
                    ))}
                    <Form.Item>
                      <Button
                        type="dashed"
                        onClick={() => add()}
                        block
                        icon={<PlusOutlined />}
                      >
                        Add color
                      </Button>
                    </Form.Item>
                  </>
                )}
              </Form.List>
            )}
          </Form>
        </Col>
      </Row>
    </Modal>
  )
}

export default UpsertModal
const { Search } = Input
