import React, { useEffect, useState } from "react"

import * as Http from "utils/http.helper"
import toastr from "toastr"
import UpsertModal from "./modals/upsert"
import "toastr/build/toastr.min.css"
import {
  Table,
  Tag,
  Button,
  Form,
  Input,
  Select,
  Modal,
  Card,
  Tooltip,
} from "antd"

const UserList = () => {
  document.title = "User List | EASY JET CONNECT"
  const [form] = Form.useForm()
  const [users, setUsers] = useState([])
  const [upsertModal, setUpsertModal] = useState({ show: false, data: [] })
  const [suspectModal, setSuspectModal] = useState({ show: false, data: null })
  const [statusList, setStatusList] = useState([])
  const [loading, setLoading] = useState(true)

  const getData = () => {
    setLoading(true)
    Http.get("api/users/getUsers")
      .then(res => {
        if (res?.success) {
          setLoading(false)
          setStatusList(
            res?.data?.statusList.map(item => {
              return { label: item.name, value: item.id }
            })
          )
          setUsers(
            res?.data?.users.map(item => {
              let status = res?.data?.statusList.find(x => x.id === item.status)
              return {
                ...item,
                status: status?.name,
                statusCode: status?.id,
              }
            })
          )
        }
      })
      .catch(function (error) {
        setLoading(false)
        toastr.error(error.response.Message)
      })
  }

  useEffect(() => {
    getData()
  }, [])

  const columns = [
    {
      title: "Name Surname",
      dataIndex: "nameSurname",
      key: "nameSurname",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Role",
      dataIndex: "role",
      key: "role",
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      render: (_, row) => {
        return (
          row?.actions?.length > 0 && <Tag key={row.id}>{row.actions[0]}</Tag>
        )
      },
    },
    {
      title: "Categories",
      dataIndex: "userCategories",
      key: "userCategories",
      render: (_, row) => {
        let text = ""
        if (row?.userCategories) {
          row.userCategories.map((item, index) =>
            index > 0
              ? (text = text + ", " + item.categoryName)
              : (text = item.categoryName)
          )
        }
        return text.length > 0 && <Tag key={text}>{text}</Tag>
      },
    },
    {
      title: "Company Name",
      dataIndex: "companyName",
      key: "companyName",
    },
    {
      title: "Phone Number",
      dataIndex: "phoneNumber",
      key: "phoneNumber",
    },
    {
      title: " ",
      key: "action",
      align: "center",
      width: 120,
      hidden:
        JSON.parse(localStorage.getItem("authUserV0"))?.roleId === 7 ||
        JSON.parse(localStorage.getItem("authUserV0"))?.roleId === 1
          ? false
          : true,
      render: (_, record) => (
        <div className="d-flex align-items-center justify-content-center gap-1">
          <Tooltip placement="top" title={"Edit User"}>
            <Button
              type="dashed"
              className="btn btn-primary d-flex align-items-center justify-content-center m-auto"
              icon={<i className="mdi mdi-pencil" />}
              onClick={() => {
                setUpsertModal({ show: true, data: record })
              }}
            />
          </Tooltip>
        </div>
      ),
    },
  ].filter(item => !item.hidden)

  const handleUserSuspect = values => {
    const obj = {
      id: suspectModal?.data?.id,
      status: values.status,
    }
    Http.post("api/users/ChangeUserStatus", obj)
      .then(res => {
        if (res.success) {
          getData()
          toastr.success(
            `${suspectModal?.data?.firstName} ${suspectModal?.data?.lastName}'s status has been updated`,
            "Process"
          )
          form.resetFields()
          setSuspectModal({ show: false, data: null })
        } else {
          toastr.error(
            `Could not update ${suspectModal?.data?.firstName} ${suspectModal?.data?.lastName}'s status`,
            "Process"
          )
        }
      })
      .catch(function (error) {
        toastr.error(error)
      })
  }

  return (
    <React.Fragment>
      <div className="page-content mb-3">
        {upsertModal.show && (
          <UpsertModal
            upsertModal={upsertModal}
            setUpsertModal={setUpsertModal}
            getData={getData}
            setLoading={setLoading}
          />
        )}

        {suspectModal.show && (
          <Modal
            title="Kişi Yasakla"
            centered
            maskClosable={false}
            open={true}
            onOk={() =>
              form
                .validateFields()
                .then(val => {
                  handleUserSuspect(val)
                })
                .catch(err => {})
            }
            onCancel={() => {
              form.resetFields()
              setSuspectModal({ show: false, data: null })
            }}
            okText="Yasakla"
            cancelText="Kapat"
          >
            <Card size="small">
              <Form
                form={form}
                layout="vertical"
                initialValues={{
                  nameSurname: suspectModal?.data?.nameSurname,
                }}
              >
                <Form.Item
                  label="Name Surname"
                  name="nameSurname"
                  rules={[
                    {
                      required: true,
                      message: "This field is required.",
                    },
                  ]}
                >
                  <Input
                    type="text"
                    disabled={suspectModal?.data?.nameSurname}
                  />
                </Form.Item>
                <Form.Item
                  label="Status"
                  name="status"
                  rules={[
                    {
                      required: true,
                      message: "This field is required.",
                    },
                  ]}
                >
                  <Select placeholder="Select..." options={statusList} />
                </Form.Item>
              </Form>
            </Card>
          </Modal>
        )}

        <Card
          title="User List"
          extra={
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
              }}
            >
              {" "}
              <Button
                className="btn btn-primary d-flex align-items-center justify-content-end"
                onClick={() => setUpsertModal({ show: true, data: null })}
                icon={<i className="bx bx-plus font-size-16 align-middle"></i>}
              >
                Create New User
              </Button>
            </div>
          }
        >
          <Table
            bordered
            columns={columns}
            dataSource={users}
            loading={loading}
          />
        </Card>
      </div>
    </React.Fragment>
  )
}

export default UserList
