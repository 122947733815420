module.exports = {
  google: {
    API_KEY: "",
    CLIENT_ID:
      "23144678283-oek7ncjmmrgkgmi2i56sc411gp71a8sp.apps.googleusercontent.com",
    SECRET: "",
  },
  facebook: {
    APP_ID: "",
  },
  apiVersion: "v1",
  // apiUrl: "https://localhost:7033",
  apiUrl: "https://apieasyjetconnect.xyz",
  // mapKey: "AIzaSyDB-Gf_4HqoJw1hwPOyAQb8Jni1YGaDDws",
  mapKey: "AIzaSyDB-Gf_4HqoJw1hwPOyAQb8Jni1YGaDDws", // yeni Api key alındı.
  node: {
    fs: "empty",
  },
  plugins: [require("autoprefixer")],
}
