import React, { useState } from "react"
import {
  Form,
  Input,
  Col,
  Row,
  Card,
  Space,
  Button,
  ColorPicker,
  Spin,
  Upload,
  Tooltip,
} from "antd"
import { Container } from "reactstrap"
import * as Http from "utils/http.helper"
import { MinusCircleOutlined, UploadOutlined } from "@ant-design/icons"
import { useNavigate } from "react-router-dom"
import toastr from "toastr"
import Sounds from "utils/sounds"
import "toastr/build/toastr.min.css"

const css = `.ant-tag{
  white-space:normal!important;
  padding: 0 15px
}
.ant-space-item:first-child,.ant-space-item:nth-child(2) ,.ant-space-item:nth-child(3) {
  width:100%;
}
.ant-color-picker-trigger{
  width:100%;
  justify-content: start;
}
.ant-modal-body{
 max-height:600px;
 overflow-x:hidden!important;
 overflow-y:auto!important;
}
.ant-upload.ant-upload-select{
  width:100%!important;
  height:40px!important;
  background:white!important;
}
.ant-form-item:hover button{
  color:#1677ff!important;
}
.ant-form-item:hover label{
  color:#1677ff!important;
}
.ant-form-item {
  margin-bottom:5px!important
}
.ant-upload-list-item-container{
  width:100%!important;
  background:white!important;
}
`

// .ant-upload-list-item-actions button{display:none}
const AddTemplateImage = () => {
  const [form] = Form.useForm()
  const [form_upload] = Form.useForm()
  let navigate = useNavigate()
  const positionList = {
    front: [
      {
        name: "Front Center",
        code: "front_center",
      },
      {
        name: "Front Bottom Center",
        code: "front_bottom_center",
      },
      {
        name: "Front Left Lower Corner",
        code: "front_left_lower_corner",
      },
      {
        name: "Front Right Lower Corner ",
        code: "front_right_lower_corner",
      },
      {
        name: "Front Vertical on the Right",
        code: "front_vertical_on_the_right",
      },
    ],
    back: [
      {
        name: "Back Center",
        code: "back_center",
      },
      {
        name: "Back Bottom Center",
        code: "back_bottom_center",
      },
      {
        name: "Back Right Lower Corner",
        code: "back_right_lower_corner",
      },
    ],
    inside: [
      {
        name: "Inside Center",
        code: "inside_center",
      },

      {
        name: "Inside Left Side",
        code: "inside_left_side",
      },
      {
        name: "Inside Right Side",
        code: "inside_right_side",
      },
      {
        name: "Inside Upper Side",
        code: "inside_upper_side",
      },
      {
        name: "Inside Left Lower Corner",
        code: "inside_left_lower_corner",
      },
    ],
    other: [
      {
        name: "Tools",
        code: "tools",
      },
      {
        name: "Spine",
        code: "spine",
      },
      {
        name: "Stones",
        code: "stones",
      },
      {
        name: "Guitar Pick",
        code: "guitar_pick",
      },
    ],
  }
  const [loading, setLoading] = useState(false)
  const [imageList, setImageList] = useState([])

  const handleSave = () => {
    const values = form.getFieldsValue()
    const arr = [
      {
        sku: values.sku.trim(),
        colorHexCode: values.hexCode
          ? values.hexCode.includes("#")
            ? values.hexCode
            : "#" + values.hexCode.trim()
          : "#000000",
        price: values.price
          ? values.price.includes(",")
            ? values.price.replace(",", ".")
            : values.price
          : 0,
      },
    ]
    values.skuColorList?.length > 0 &&
      values.skuColorList.map(item => {
        arr.push({
          sku: item.sku.trim(),
          colorHexCode: item.hexCode
            ? item.hexCode.includes("#")
              ? item.hexCode
              : "#" + item.hexCode.trim()
            : "#000000",
          price: item.price
            ? item.price.includes(",")
              ? item.price.replace(",", ".")
              : item.price
            : 0,
        })
      })

    const formData = new FormData()
    formData.append(`productName`, values.productName)
    arr.map((item, index) => {
      formData.append(`skuEngraving[${index}].sku`, item.sku)
      formData.append(`skuEngraving[${index}].colorHexCode`, item.colorHexCode)
      formData.append(`skuEngraving[${index}].price`, item.price)
    })

    imageList.map((item, index) => {
      formData.append(`positionImage[${index}].type`, item?.type)
      formData.append(`positionImage[${index}].image`, item?.img)
      formData.append(`positionImage[${index}].positionCode[0]`, item?.position)
    })

    Http.post_form_data("api/orders/addEngravingImages", formData)
      .then(res => {
        if (res.success) {
          setLoading(false)
          toastr.success(`Görseller eklenmiştir.`)
          navigate("/engraving-image-management")
          Sounds.success()
        }
      })
      .catch(function (error) {
        Sounds.error()
        setLoading(false)
      })
  }

  const layout = {
    labelCol: {
      span: 8,
    },
    wrapperCol: {
      span: 16,
    },
  }

  return (
    <React.Fragment>
      <Spin spinning={loading}>
        <div className="page-content mb-3">
          <Container fluid>
            <Card
              title="Add Engravin Template"
              hoverable
              extra={
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "end",
                  }}
                >
                  <Row>
                    <Col
                      lg={24}
                      className="d-flex align-items-center justify-content-end gap-2"
                    >
                      <Button
                        type="dashed"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                        onClick={() => {
                          navigate("/engraving-image-management")
                        }}
                        icon={
                          <i className="bx bx-arrow-back font-size-16 align-middle mr-2"></i>
                        }
                      >
                        Return to Engraving Image List
                      </Button>
                      <Tooltip
                        title={
                          imageList.length === 0
                            ? "You must upload at least 1 template image for the save process."
                            : ""
                        }
                        color="#556ee6"
                      >
                        <div
                          style={{
                            maxWidth: 200,
                            width: "100%",
                            cursor: "pointer",
                          }}
                        >
                          <Button
                            type="button"
                            className="btn btn-primary d-flex align-items-center justify-content-center mt-1"
                            style={{ width: "200px", cursor: "pointer" }}
                            disabled={imageList.length === 0}
                            onClick={() => {
                              setLoading(true)
                              setTimeout(() => {
                                form
                                  .validateFields()
                                  .then(() => {
                                    handleSave()
                                  })
                                  .catch(err => {
                                    return false
                                  })
                              }, 350)
                            }}
                          >
                            Engraving Template Save
                          </Button>
                        </div>
                      </Tooltip>
                    </Col>
                  </Row>
                </div>
              }
            >
              <style>{css}</style>
              <Form form={form} layout="vertical">
                <Card className="mb-3" hoverable>
                  <Row gutter={[16, 0]}>
                    <Col lg={7}>
                      <Form.Item
                        name={"productName"}
                        label={`Product Name  `}
                        rules={[
                          {
                            required: true,
                            message: "This field is required!",
                          },
                        ]}
                      >
                        <Input
                          placeholder="Please enter product name!"
                          style={{ width: "87%" }}
                        />
                      </Form.Item>
                    </Col>
                    <Col lg={20}>
                      <Space
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "start",
                          width: "100%",
                          columnGap: 15,
                        }}
                        align="baseline"
                      >
                        <Form.Item
                          name={"sku"}
                          label={`Sku`}
                          rules={[
                            {
                              required: true,
                              message: "This field is required!",
                            },
                          ]}
                        >
                          <Input
                            placeholder="Plese enter the sku!"
                            style={{ width: "100%" }}
                          />
                        </Form.Item>
                        <Form.Item name={"price"} label={`Price`}>
                          <Input
                            placeholder="Plese enter the price!"
                            style={{ width: "100%" }}
                          />
                        </Form.Item>
                        <Form.Item name="hexCode" label={`HexCode`}>
                          <Input
                            placeholder="Plese enter the hex code!"
                            style={{ width: "100%" }}
                            onChange={e => {
                              form.setFieldsValue({
                                hexCodeShow: e.target.value.includes("#")
                                  ? e.target.value.split("#")[1]
                                  : e.target.value,
                              })
                            }}
                          />
                        </Form.Item>
                        <Form.Item name="hexCodeShow" label={` `}>
                          <ColorPicker
                            onChange={e => {
                              form.setFieldsValue({
                                hexCode: e.toHexString(),
                              })
                            }}
                          />
                        </Form.Item>
                        <MinusCircleOutlined
                          style={{ marginTop: 28, opacity: 0 }}
                        />
                      </Space>
                    </Col>
                    <Col lg={20}>
                      <Form.List name="skuColorList" style={{ width: "50%" }}>
                        {(fields, { add, remove }) => (
                          <>
                            {fields.map(({ key, name, ...restField }) => (
                              <Space
                                key={key}
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "start",
                                  width: "100%",
                                  columnGap: 15,
                                }}
                                align="baseline"
                              >
                                <Form.Item
                                  {...restField}
                                  name={[name, "sku"]}
                                  label={`Sku  ${name + 1}`}
                                  rules={[
                                    {
                                      required: true,
                                      message: "Sku alanı zorunludur.",
                                    },
                                  ]}
                                >
                                  <Input
                                    placeholder="Lütfen engraving'e ait sku giriniz..."
                                    style={{ width: "100%" }}
                                  />
                                </Form.Item>
                                <Form.Item
                                  {...restField}
                                  name={[name, "price"]}
                                  label={`Price  ${name + 1}`}
                                  rules={[
                                    {
                                      required: true,
                                      message: "Price alanı zorunludur.",
                                    },
                                  ]}
                                >
                                  <Input
                                    placeholder="Lütfen engraving'e ait price giriniz..."
                                    style={{ width: "100%" }}
                                  />
                                </Form.Item>
                                <Form.Item
                                  {...restField}
                                  name={[name, "hexCode"]}
                                  label={` HexCode ${name + 1}`}
                                >
                                  <Input
                                    placeholder="Lütfen engraving'e ait hexcode giriniz..."
                                    style={{ width: "100%" }}
                                    onChange={e => {
                                      const fields = form.getFieldsValue()
                                      const { skuColorList } = fields

                                      ;(skuColorList[name].hexCodeShow =
                                        skuColorList[name].hexCode.includes("#")
                                          ? skuColorList[name].hexCode.split(
                                              "#"
                                            )[1]
                                          : skuColorList[name].hexCode),
                                        form.setFieldsValue({
                                          skuColorList,
                                        })
                                    }}
                                  />
                                </Form.Item>

                                <Form.Item
                                  {...restField}
                                  name={[name, "hexCodeShow"]}
                                  label=" "
                                >
                                  <ColorPicker
                                    onChange={e => {
                                      const fields = form.getFieldsValue()
                                      const { skuColorList } = fields

                                      skuColorList[name].hexCode =
                                        skuColorList[
                                          name
                                        ].hexCodeShow.toHexString()
                                      form.setFieldsValue({
                                        skuColorList,
                                      })
                                    }}
                                  />
                                </Form.Item>
                                <MinusCircleOutlined
                                  onClick={() => remove(name)}
                                  style={{ marginTop: 28, color: "red" }}
                                />
                              </Space>
                            ))}
                            <Form.Item>
                              <Button
                                type="dashed"
                                className="d-flex align-items-center justify-content-center mt-3"
                                onClick={() => add()}
                                style={{
                                  width: "100%",
                                }}
                                icon={<UploadOutlined />}
                              >
                                Add sku and color values for engraving
                              </Button>
                            </Form.Item>
                          </>
                        )}
                      </Form.List>
                    </Col>
                  </Row>
                </Card>
              </Form>
              <Form form={form_upload} {...layout}>
                <Row gutter={[16, 16]}>
                  <Col lg={12}>
                    <Card
                      title="Front Template Area"
                      hoverable
                      style={{ minHeight: 370 }}
                    >
                      {positionList.front.map((item, index) => {
                        return (
                          <Form.Item
                            name={item.code}
                            label={item.name}
                            key={index}
                          >
                            <Upload
                              name="avatar"
                              listType="picture-card"
                              className="avatar-uploader"
                              onRemove={file => {
                                let arr = []
                                imageList.map(x => {
                                  if (x.img.uid !== file.uid) {
                                    arr.push(x)
                                  }
                                })
                                setImageList(arr)
                              }}
                              fileList={
                                imageList.length > 0
                                  ? imageList.find(
                                      x => x.position === item.code
                                    )
                                    ? [
                                        imageList.find(
                                          x => x.position === item.code
                                        ).img,
                                      ]
                                    : []
                                  : []
                              }
                              beforeUpload={file => {
                                file.url = URL.createObjectURL(file)
                                if (imageList.length > 0) {
                                  //güncelleme işlemi
                                  let arr = []
                                  imageList.map(x => {
                                    if (x.position !== item.code) {
                                      arr.push(x)
                                    }
                                  })
                                  arr.push({
                                    position: item.code,
                                    img: file,
                                    type: 10,
                                  })
                                  setImageList(arr)
                                } else {
                                  setImageList([
                                    {
                                      position: item.code,
                                      img: file,
                                      type: 10,
                                    },
                                  ])
                                }
                              }}
                            >
                              {imageList.find(
                                x => x.position === item.code
                              ) ? null : (
                                <button
                                  style={{
                                    border: 0,
                                    background: "none",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    columnGap: 10,
                                  }}
                                  type="button"
                                >
                                  <UploadOutlined />
                                  <div>Upload Template Image</div>
                                </button>
                              )}
                            </Upload>
                          </Form.Item>
                        )
                      })}
                    </Card>
                  </Col>
                  <Col lg={12}>
                    <Card
                      title="Inside Template Area"
                      hoverable
                      style={{ minHeight: 370 }}
                    >
                      {positionList.inside.map((item, index) => {
                        return (
                          <Form.Item
                            name={item.code}
                            label={item.name}
                            key={index}
                          >
                            <Upload
                              name="avatar"
                              listType="picture-card"
                              className="avatar-uploader"
                              onRemove={file => {
                                let arr = []
                                imageList.map(x => {
                                  if (x.img.uid !== file.uid) {
                                    arr.push(x)
                                  }
                                })
                                setImageList(arr)
                              }}
                              fileList={
                                imageList.length > 0
                                  ? imageList.find(
                                      x => x.position === item.code
                                    )
                                    ? [
                                        imageList.find(
                                          x => x.position === item.code
                                        ).img,
                                      ]
                                    : []
                                  : []
                              }
                              beforeUpload={file => {
                                file.url = URL.createObjectURL(file)
                                if (imageList.length > 0) {
                                  //güncelleme işlemi
                                  let arr = []
                                  imageList.map(x => {
                                    if (x.position !== item.code) {
                                      arr.push(x)
                                    }
                                  })
                                  arr.push({
                                    position: item.code,
                                    img: file,
                                    type: 30,
                                  })
                                  setImageList(arr)
                                } else {
                                  setImageList([
                                    {
                                      position: item.code,
                                      img: file,
                                      type: 30,
                                    },
                                  ])
                                }
                              }}
                            >
                              {imageList.find(
                                x => x.position === item.code
                              ) ? null : (
                                <button
                                  style={{
                                    border: 0,
                                    background: "none",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    columnGap: 10,
                                  }}
                                  type="button"
                                >
                                  <UploadOutlined />
                                  <div>Upload Template Image</div>
                                </button>
                              )}
                            </Upload>
                          </Form.Item>
                        )
                      })}
                    </Card>
                  </Col>
                  <Col lg={12}>
                    <Card
                      title="Back Template Area"
                      hoverable
                      style={{ minHeight: 300 }}
                    >
                      {positionList.back.map((item, index) => {
                        return (
                          <Form.Item
                            name={item.code}
                            label={item.name}
                            key={index}
                          >
                            <Upload
                              name="avatar"
                              listType="picture-card"
                              className="avatar-uploader"
                              onRemove={file => {
                                let arr = []
                                imageList.map(x => {
                                  if (x.img.uid !== file.uid) {
                                    arr.push(x)
                                  }
                                })
                                setImageList(arr)
                              }}
                              fileList={
                                imageList.length > 0
                                  ? imageList.find(
                                      x => x.position === item.code
                                    )
                                    ? [
                                        imageList.find(
                                          x => x.position === item.code
                                        ).img,
                                      ]
                                    : []
                                  : []
                              }
                              beforeUpload={file => {
                                file.url = URL.createObjectURL(file)
                                if (imageList.length > 0) {
                                  //güncelleme işlemi
                                  let arr = []
                                  imageList.map(x => {
                                    if (x.position !== item.code) {
                                      arr.push(x)
                                    }
                                  })
                                  arr.push({
                                    position: item.code,
                                    img: file,
                                    type: 20,
                                  })
                                  setImageList(arr)
                                } else {
                                  setImageList([
                                    {
                                      position: item.code,
                                      img: file,
                                      type: 20,
                                    },
                                  ])
                                }
                              }}
                            >
                              {imageList.find(
                                x => x.position === item.code
                              ) ? null : (
                                <button
                                  style={{
                                    border: 0,
                                    background: "none",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    columnGap: 10,
                                  }}
                                  type="button"
                                >
                                  <UploadOutlined />
                                  <div>Upload Template Image</div>
                                </button>
                              )}
                            </Upload>
                          </Form.Item>
                        )
                      })}
                    </Card>
                  </Col>
                  <Col lg={12}>
                    <Card
                      title="Other Template Area"
                      hoverable
                      style={{ minHeight: 300 }}
                    >
                      {positionList.other.map((item, index) => {
                        return (
                          <Form.Item
                            name={item.code}
                            label={item.name}
                            key={index}
                          >
                            <Upload
                              name="avatar"
                              // beforeUpload={() => false}
                              listType="picture-card"
                              className="avatar-uploader"
                              onRemove={file => {
                                let arr = []
                                imageList.map(x => {
                                  if (x.img.uid !== file.uid) {
                                    arr.push(x)
                                  }
                                })
                                setImageList(arr)
                              }}
                              fileList={
                                imageList.length > 0
                                  ? imageList.find(
                                      x => x.position === item.code
                                    )
                                    ? [
                                        imageList.find(
                                          x => x.position === item.code
                                        ).img,
                                      ]
                                    : []
                                  : []
                              }
                              beforeUpload={file => {
                                file.url = URL.createObjectURL(file)
                                if (imageList.length > 0) {
                                  //güncelleme işlemi
                                  let arr = []
                                  imageList.map(x => {
                                    if (x.position !== item.code) {
                                      arr.push(x)
                                    }
                                  })
                                  arr.push({
                                    position: item.code,
                                    img: file,
                                    type:
                                      item.code === "spine"
                                        ? 40
                                        : item.code === "tools"
                                        ? 50
                                        : item.code === "stones"
                                        ? 60
                                        : 70,
                                  })
                                  setImageList(arr)
                                } else {
                                  setImageList([
                                    {
                                      position: item.code,
                                      img: file,
                                      type:
                                        item.code === "spine"
                                          ? 40
                                          : item.code === "tools"
                                          ? 50
                                          : item.code === "stones"
                                          ? 60
                                          : 70,
                                    },
                                  ])
                                }
                              }}
                            >
                              {imageList.find(
                                x => x.position === item.code
                              ) ? null : (
                                <button
                                  style={{
                                    border: 0,
                                    background: "none",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    columnGap: 10,
                                  }}
                                  type="button"
                                >
                                  <UploadOutlined />
                                  <div>Upload Template Image</div>
                                </button>
                              )}
                            </Upload>
                          </Form.Item>
                        )
                      })}
                    </Card>
                  </Col>
                </Row>
              </Form>
            </Card>
          </Container>{" "}
        </div>
      </Spin>
    </React.Fragment>
  )
}

export default AddTemplateImage
