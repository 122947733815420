import React, { createContext, useReducer } from "react"

// Context oluşturma

const initialState = {
  customers: [
    { id: 1, name: "John Doe", role: "Admin" },
    { id: 2, name: "Jane Smith", role: "User" },
  ],
  dtfDownloadLoading: [{ id: 222, value: "Jane Smith" }],
  tableParams: {
    current: 1,
    pageSize: 10,
    total: 0,
  },
}

const customReducer = (state, action) => {
  console.log("action: ", action)
  switch (action.type) {
    case "ADD_CUSTOMER":
      return {
        ...state,
        customers: [...state.customers, action.payload],
      }
    case "ADD_DTF_DOWNLOAD_LOADING":
      return {
        ...state,
        dtfDownloadLoading: [...state.dtfDownloadLoading, action.payload],
      }
    case "Update_DTF_DOWNLOAD_LOADING":
      dtfDownloadLoading[payload.index].value = action.payload.value
      return {
        ...state,
        dtfDownloadLoading: [dtfDownloadLoading],
      }
    case "SET_TABLEPARAMS":
      return {
        ...state,
        tableParams: action.payload,
      }
    default:
      return state
  }
}

export const CustomContext = createContext()

// Provider bileşeni
export const CustomProvider = ({ children }) => {
  const [state, dispatch] = useReducer(customReducer, initialState)

  return (
    <CustomContext.Provider value={{ state, dispatch }}>
      {children}
    </CustomContext.Provider>
  )
}
