import React, { useEffect, useState, useRef } from "react"
import UpsertModal from "./modals/upsert"
import * as Http from "utils/http.helper"
import {
  Table,
  Button,
  Tooltip,
  Select,
  Form,
  Result,
  Collapse,
  Row,
  Col,
  Card,
  Tag,
} from "antd"
import { getColumnSearchProps } from "utils/filter"
import toastr from "toastr"
import "toastr/build/toastr.min.css"
const { Panel } = Collapse

const MainCompanyUserList = () => {
  document.title = "Company Admin List | EASY JET CONNECT"
  const searchInput = useRef(null)
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(true)
  const [userList, setUserList] = useState([])
  const [companyId, setCompanyId] = useState(null)
  const [upsertModal, setUpsertModal] = useState({ show: false, data: null })
  const [companyList, setCompanyList] = useState([])
  const [searchText, setSearchText] = useState("")

  const getCompany = () => {
    Http.get("api/companies/getMainCompanies")
      .then(res => {
        setLoading(false)
        setCompanyList(
          res.data.reverse().map(item => {
            return { value: item.id, label: item.name }
          })
        )
      })
      .catch(function (error) {
        setLoading(false)
      })
  }
  useEffect(() => {
    if (companyList.length == 0) {
      getCompany()
    }
  }, [])

  const getData = id => {
    if (id || companyId) {
      setLoading(true)
      Http.get(`api/users/GetCompanyAdmins?CompanyId=${id ? id : companyId}`)
        .then(res => {
          if (res?.success) {
            setLoading(false)
            setUserList(res.data.users)
          }
        })
        .catch(function (error) {
          setLoading(false)
          toastr.error(error.response.message)
        })
    } else {
      setLoading(false)
    }
  }

  const columns = [
    {
      title: "Name Surname",
      dataIndex: "nameSurname",
      key: "nameSurname",
      ...getColumnSearchProps(
        "nameSurname",
        "Filter by name surname",
        searchInput,
        searchText,
        setSearchText
      ),
      sorter: (a, b) => a.nameSurname.localeCompare(b.nameSurname),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      ...getColumnSearchProps(
        "email",
        "Filter by email",
        searchInput,
        searchText,
        setSearchText
      ),
      sorter: (a, b) => a.email.localeCompare(b.email),
    },
    {
      title: "Role",
      dataIndex: "role",
      key: "role",
      ...getColumnSearchProps(
        "role",
        "Filter by role",
        searchInput,
        searchText,
        setSearchText
      ),
      sorter: (a, b) => a.role.localeCompare(b.role),
    },
    {
      title: "Categories",
      dataIndex: "userCategories",
      key: "userCategories",
      render: (_, row) => {
        let text = ""
        if (row?.userCategories) {
          row.userCategories.map((item, index) =>
            index > 0
              ? (text = text + ", " + item.categoryName)
              : (text = item.categoryName)
          )
        }
        return text.length > 0 && <Tag key={text}>{text}</Tag>
      },
    },
    {
      title: "Phone Number",
      dataIndex: "phoneNumber",
      key: "phoneNumber",
      ...getColumnSearchProps(
        "phoneNumber",
        "Filter by phoneNumber",
        searchInput,
        searchText,
        setSearchText
      ),
      sorter: (a, b) => a.phoneNumber.localeCompare(b.phoneNumber),
    },
    {
      title: "Company Name",
      dataIndex: "companyName",
      key: "companyName",
      ...getColumnSearchProps(
        "companyName",
        "Filter by companyName",
        searchInput,
        searchText,
        setSearchText
      ),
      sorter: (a, b) => a.companyName.localeCompare(b.companyName),
    },
    {
      title: " ",
      key: "action",
      align: "center",
      width: 120,
      render: (_, record) => (
        <Tooltip placement="top" title="Edit Company Admin">
          <Button
            type="dashed"
            className="btn btn-primary d-flex align-items-center justify-content-center m-auto"
            icon={<i className="mdi mdi-pencil" />}
            onClick={() => {
              setUpsertModal({ show: true, data: record })
            }}
          />
        </Tooltip>
      ),
    },
  ]

  let locale = {
    emptyText: (
      <Result
        title="No data"
        subTitle="You haven't selected any companies or added users yet."
      />
    ),
  }
  return (
    <React.Fragment>
      {upsertModal.show && (
        <UpsertModal
          upsertModal={upsertModal}
          setUpsertModal={setUpsertModal}
          getData={getData}
          setLoading={setLoading}
          companyList={companyList}
          companyId={companyId}
        />
      )}

      <div className="page-content mb-3">
        {loading || companyList.length > 0 ? (
          <Collapse activeKey={["1"]} expandIcon={() => false}>
            <Panel
              key="1"
              header={
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  Company Admin List
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "end",
                      columnGap: "15px",
                    }}
                  >
                    <Button
                      className="btn btn-primary d-flex align-items-center justify-content-end"
                      onClick={() => setUpsertModal({ show: true, data: null })}
                      icon={
                        <i className="bx bx-plus font-size-16 align-middle"></i>
                      }
                    >
                      Add Company Admin
                    </Button>
                  </div>
                </div>
              }
            >
              <Form form={form} layout="vertical">
                <Row>
                  <Col lg={4}>
                    <Form.Item label="Company" name="company" required>
                      <Select
                        allowClear
                        showSearch
                        placeholder="Select a company"
                        onChange={e => {
                          if (e) {
                            getData(e)

                            setCompanyId(e)
                          } else {
                            setUserList([])
                          }
                        }}
                        options={companyList}
                        style={{ minWidth: "150px" }}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Form>

              <Table
                locale={locale}
                bordered
                title={false}
                columns={columns}
                dataSource={userList}
                loading={loading}
                rowKey={record => record.id}
              />
            </Panel>
          </Collapse>
        ) : (
          <Row>
            <Col lg={24}>
              <Card>
                <div className="alert alert-danger d-none d-lg-block">
                  After creating the main company, you can start your
                  transactions in the user list.
                </div>
              </Card>
            </Col>
          </Row>
        )}
      </div>
    </React.Fragment>
  )
}

export default MainCompanyUserList
