import React, { useState, useRef } from "react"
import config from "config"
import GooglePlacesAutocomplete from "react-google-places-autocomplete"
const Maps = ({ setValue, value }) => {
  return (
    <GooglePlacesAutocomplete
      apiKey={config.mapKey}
      autocompletionRequest={{
        componentRestrictions: {
          country: ["us"], //to set the specific country
        },
        language: "EN",
        type: "geocode",
      }}
      selectProps={{
        defaultInputValue: value, //set default value
        onChange: setValue, //save the value gotten from google
        placeholder: "Plase enter address line 1...",
      }}
      onLoadFailed={error => {}}
    />
  )
}

export default Maps
