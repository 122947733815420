import React, { useState, useEffect } from "react"
import toastr from "toastr"
import * as moment from "moment"
import * as Http from "utils/http.helper"
import {
  Table,
  Card,
  Button,
  Row,
  Col,
  Select,
  Spin,
  Form,
  Tag,
  Alert,
  Space,
  Input,
  Modal,
} from "antd"
import Papa from "papaparse"
import Dropzone from "react-dropzone"
import { useLocation, useNavigate } from "react-router-dom"
import "toastr/build/toastr.min.css"

const allowedExtensions = ["csv"]
const css = `
.disabled-row{
  opacity:0.6
}.empty_select_option .ant-select-selector{
  border :1px solid red!important;
  background: #ff000054!important;
}
.empty_select_option .ant-select-arrow{
  color:rgb(255 0 0)!important;
}
.ant-form-item{
    margin-bottom:0!important;
}
.custom_btn{
    background: #ffffff;
    color: #0958d9;
    border-color: #0958d9;
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.02);
    border-style: dashed;
}`

const ApparelAdd = () => {
  document.title = "Apparel Add | EASY JET CONNECT"
  const navigate = useNavigate()
  const { search } = useLocation()
  const params = new URLSearchParams(search)
  const maxDate = params.get("maxDate")
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState([])
  const [options, setOptions] = useState([])
  const [error, setError] = useState("")
  const [file, setFile] = useState("")
  const [currentPage, setPage] = useState(1)
  const [financialList, setFinancialList] = useState([])
  const [orderArrayList, setOrderArrayList] = useState([])
  const [tshirtSize, setTshirtSize] = useState([])
  const [sweatshirtSize, setSweatshirtSize] = useState([])
  const [toodlerSize, setToddlerSize] = useState([])
  const [hoodieSize, setHoodieSize] = useState([])
  const [onesieSize, setOnesieSize] = useState([])
  const [comfortSize, setComfortSize] = useState([])
  const [form] = Form.useForm()
  const [formV1] = Form.useForm()
  const [fileName, setFileName] = useState(null)
  const [haveOrderBefore, setHaveOrderBefore] = useState(true)
  const [questionModal, setQuestionModal] = useState(false)
  const [notSaveList, setNotSaveList] = useState([])

  useEffect(() => {
    Http.get(`api/orders/HaveOrderBefore?categoryId=1`)
      .then(res => {
        if (res?.success) {
          setHaveOrderBefore(!res.data)
        }
      })
      .catch(function (error) {})

    Http.get(`api/orders/GetAllProperties`)
      .then(res => {
        if (res?.success) {
          res.data.map(item => {
            // 27 tshirt
            // 28 sweatshirt
            // 30 hoodle
            // 31 youth  -->yapılmadı
            // 32 toodler
            // 33 onesie
            if (item.styleId === 27) {
              //tshirt
              setTshirtSize(
                item.sizes.map(x => {
                  return { label: x.name, value: x.id }
                })
              )
            } else if (item.styleId === 28) {
              //sweatShirt
              setSweatshirtSize(
                item.sizes.map(x => {
                  return { label: x.name, value: x.id }
                })
              )
            } else if (item.styleId === 30) {
              //hoodie
              setHoodieSize(
                item.sizes.map(x => {
                  return { label: x.name, value: x.id }
                })
              )
            } else if (item.styleId === 32) {
              //toodler
              setToddlerSize(
                item.sizes.map(x => {
                  return { label: x.name, value: x.id }
                })
              )
            } else if (item.styleId === 33) {
              //onesie
              setOnesieSize(
                item.sizes.map(x => {
                  return { label: x.name, value: x.id }
                })
              )
            } else if (item.styleId === 43) {
              setComfortSize(
                item.sizes.map(x => {
                  return { label: x.name, value: x.id }
                })
              )
            }
          })
        }
      })
      .catch(function (error) {
        setLoading(false)
        toastr.error(error.response.data.Message)
      })
  }, [])

  useEffect(() => {
    Http.get(`api/orders/getOldAddedOrders?categoryId=1`)
      .then(res => {
        if (res?.success) {
          res.data.length > 0 && setOrderArrayList(res.data)
        }
      })
      .catch(function (error) {})
  }, [])
  useEffect(() => {
    setLoading(true)
    Http.get(`api/orders/getproductspricesbycompany?categoryId=1`)
      .then(res => {
        if (res?.success) {
          setLoading(false)
          setFinancialList(res.data)
        }
      })
      .catch(function (error) {
        setLoading(false)
        toastr.error(error.response.data.Message)
      })
  }, [])

  const getOptions = () => {
    setLoading(true)
    Http.get(`api/orders/GetApparelProperties`)
      .then(res => {
        if (res?.success) {
          setLoading(false)
          setOptions({
            sizes: res.data.sizes.map(item => {
              return { label: item.name, value: item.id }
            }),
            colors: res.data.colors.map(item => {
              return {
                label: item.name,
                value: item.id,
                hexCode: item.hexCode,
              }
            }),
            styles: res.data.styles.map(item => {
              return {
                label: item.name,
                value: item.id,
              }
            }),
          })
        }
      })
      .catch(function (error) {
        setLoading(false)
        toastr.error(error.response.data.Message)
      })
  }

  useEffect(() => {
    getOptions()
  }, [])

  const columns = [
    {
      title: "SKU",
      dataIndex: "sku",
    },

    {
      title: "Order No",
      dataIndex: "orderId",
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
    },
    {
      title: "Personalization",
      dataIndex: "personalization",
    },
    {
      title: "Variations",
      dataIndex: "variant",
      width: 500,
    },

    {
      title: "Style",
      dataIndex: "style",
      render: (_, row) => {
        return (
          <Form.Item className="style" name={[row.index, "style"]} label="">
            <Select
              className={row.style === "" ? "empty_select_option" : ""}
              onChange={(_, e) => handleChange(row, e, "style")}
              options={options?.styles}
              style={{ width: 150 }}
              placeholder="Style seçiniz."
              showSearch
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
            />
          </Form.Item>
        )
      },
    },
    {
      title: "Size",
      dataIndex: "size",
      render: (_, row) => {
        // 27 tshirt
        // 28 sweatshirt
        // 30 hoodle
        // 31 youth
        // 32 toodler
        // 33 onesie

        return (
          <Form.Item className="size" name={[row.index, "size"]} label="">
            <Select
              className={row.size === "" ? "empty_select_option" : ""}
              placeholder="Size seçiniz."
              onChange={(_, e) => handleChange(row, e, "size")}
              options={
                row.style === 27
                  ? tshirtSize
                  : row.style === 28
                  ? sweatshirtSize
                  : row.style === 30
                  ? hoodieSize
                  : row.style === 32
                  ? toodlerSize
                  : row.style === 33
                  ? onesieSize
                  : row.style === 43
                  ? comfortSize
                  : options?.sizes
              }
              style={{ width: 200 }}
              showSearch
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
            />
          </Form.Item>
        )
      },
    },

    {
      title: "Color",
      dataIndex: "color",
      render: (_, row) => {
        return (
          <Form.Item className="color" name={[row.index, "color"]} label="">
            <Select
              className={row.color === "" ? "empty_select_option" : ""}
              placeholder="Color seçiniz."
              onChange={(_, e) => handleChange(row, e, "color")}
              options={options?.colors}
              style={{ width: 150 }}
              showSearch
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
            />
          </Form.Item>
        )
      },
    },
    {
      title: "Price",
      dataIndex: "price",
      width: 150,
      render: (_, row) => {
        return row.price ? (
          row.price.toFixed(2) + " $"
        ) : (
          <Tag color="red" style={{ width: "100%", textAlign: "center" }}>
            {" "}
            --{" "}
          </Tag>
        )
      },
    },
    {
      title: "Sale Date",
      dataIndex: "saleDate",
      render: (_, row) => {
        return moment.utc(row.saleDate).format("DD.MM.YYYY")
      },
    },
  ]

  const handleFileChange = e => {
    setPage(1)
    setLoading(true)
    setData([])
    setFile(null)
    setError("")
    if (e.length > 0) {
      const inputFile = e[0]
      const fileExtension = inputFile?.type.split("/")[1]
      if (!allowedExtensions.includes(fileExtension)) {
        setLoading(false)
        setError("Please input a csv file")
        return
      }
      setFile(inputFile)
      setLoading(false)
    } else {
      setLoading(false)
      setError("Please input a csv file")
    }
  }

  const isFutureDate = dateString => {
    const today = maxDate !== "null" ? new Date(maxDate) : new Date()
    maxDate === "null" && today.setHours(0, 0, 0, 0)
    // Tarih stringini parçalarına ayırıyoruz (gün, ay, yıl)
    const [day, month, year] = dateString.split(".").map(Number)
    // Yeni bir tarih nesnesi oluşturuyoruz
    const date = new Date(year, month - 1, day)
    // Ay indeksinin 0'dan başladığını unutmuyoruz
    // Tarihi bugünün tarihi ile kıyaslıyoruz
    if (date >= today) {
      return true
    } else {
      return false
    }
  }

  const handleParse = value => {
    setQuestionModal(false)
    // value => true --> onaylıyor kaydedilmiyecek geçmiş
    setLoading(true)
    if (!file) return alert("Enter a valid file")
    const reader = new FileReader()
    reader.onload = async ({ target }) => {
      const csv = Papa.parse(target.result, {
        header: true,
      })
      const parsedData = csv?.data
      const columns = []
      const notSaveList = []
      let control = true
      let dateProblem = true
      let i = 0 //index değeri verilemez çünkü csv de hatalıyı okumuyoruz index ile yapılsaydı eksik veriyi göster butonu yanlış çalışır. columns arrayine pushlalanan veride index: i olmalıdır
      try {
        if (value) {
          parsedData.map((item, index) => {
            const today = new Date()
            const yearStart = today.getFullYear().toString().slice(0, 2)
            let csvYear =
              item["Sale Date"].split("/")[2].length === 4
                ? item["Sale Date"].split("/")[2]
                : yearStart + item["Sale Date"].split("/")[2]
            let csvMonth = item["Sale Date"].split("/")[0]
            let csvDay = item["Sale Date"].split("/")[1]
            let newDate = csvDay + "." + csvMonth + "." + csvYear
            if (isFutureDate(newDate)) {
              if (!orderArrayList.includes(item["Order ID"])) {
                if (item["SKU"] && item["Sale Date"] && item["Order ID"]) {
                  let textStyles = item["Variations"]
                    ? item["Variations"]
                        .split(",")[0]
                        .split(":")[1]
                        .split(" ")[0]
                    : ""
                  let textColor = item["Variations"]
                    ? item["Variations"].split(",")[1].split(":")[1]
                    : ""
                  let textSizes = item["Variations"]
                    ? item["Variations"].split(",")[0].split(":")[1].split(" ")[
                        item["Variations"]
                          .split(",")[0]
                          .split(":")[1]
                          .split(" ").length - 1
                      ]
                    : ""

                  let textPersonalization = ""
                  if (item["Variations"].includes("Month Onesie")) {
                    textStyles = "Onesie"
                    textColor = item["Variations"]
                      ? item["Variations"].split(",")[1].split(":")[1]
                      : ""
                    textSizes = item["Variations"]
                      ? item["Variations"]
                          .split(",")[0]
                          .split(":")[1]
                          .split(" ")[0] +
                        " " +
                        item["Variations"]
                          .split(",")[0]
                          .split(":")[1]
                          .split(" ")[1]
                      : ""
                  }
                  if (item["Variations"].search("Personalization") > 0) {
                    textPersonalization = item["Variations"]
                      ? item["Variations"]
                          .split(",")
                          [item["Variations"].split(",").length - 1].split(
                            ":"
                          )[1]
                      : ""
                  }
                  if (textSizes === "XXL") {
                    textSizes = "2XL"
                  }
                  if (
                    item["Variations"].split(",")[0].search("/") > 0 &&
                    !item["Variations"].includes("Month Onesie")
                  ) {
                    textStyles = item["Variations"]
                      ? item["Variations"]
                          .split(",")[0]
                          .split(":")[1]
                          .split("/")[0]
                          .split(" ")[0]
                      : ""
                    textColor = item["Variations"]
                      ? item["Variations"].split(",")[1].split(":")[1]
                      : ""
                    textSizes = item["Variations"]
                      ? item["Variations"]
                          .split(",")[0]
                          .split(":")[1]
                          .split("/")[1]
                          .trim()
                      : ""
                  } else if (
                    item["Variations"].split(",")[0].search("-") > 0 &&
                    !item["Variations"].includes("Month Onesie")
                  ) {
                    textStyles = item["Variations"]
                      ? item["Variations"]
                          .split(",")[0]
                          .split(":")[1]
                          .split("-")[0]
                          .split(" ")[0]
                      : ""
                    textColor = item["Variations"]
                      ? item["Variations"].split(",")[1].split(":")[1]
                      : ""
                    if (
                      item["Variations"].split(",")[0].split(":")[1].split("-")[
                        item["Variations"]
                          .split(",")[0]
                          .split(":")[1]
                          .split("-").length - 1
                      ] === "XXL"
                    ) {
                      textSizes = "2XL"
                    } else {
                      textSizes = item["Variations"]
                        ? item["Variations"]
                            .split(",")[0]
                            .split(":")[1]
                            .split("-")[
                            item["Variations"]
                              .split(",")[0]
                              .split(":")[1]
                              .split("-").length - 1
                          ]
                        : ""
                    }
                  } else if (textStyles.toLowerCase().trim() === "comfort") {
                    textStyles = "Comfort Colors"
                    if (
                      item["Variations"].split(",")[0].split(":")[1].split(" ")[
                        item["Variations"]
                          .split(",")[0]
                          .split(":")[1]
                          .split(" ").length - 1
                      ] === "XXL"
                    ) {
                      textSizes === "comfort colors 2xl"
                    } else {
                      textSizes = item["Variations"]
                        .split(",")[0]
                        .split(":")[1]
                        .split(" ")[
                        item["Variations"]
                          .split(",")[0]
                          .split(":")[1]
                          .split(" ").length - 1
                      ]
                    }
                  }

                  if (textStyles === "Unisex") {
                    textStyles = "t-shirt"
                  }
                  if (textStyles === "Baby") {
                    textStyles = "onesie"
                    textSizes =
                      item["Variations"].split(",")[0].split(":")[1].split(" ")
                        .length > 2
                        ? item["Variations"]
                            .split(",")[0]
                            .split(":")[1]
                            .split(" ")[1] +
                          " " +
                          item["Variations"]
                            .split(",")[0]
                            .split(":")[1]
                            .split(" ")[2]
                        : item["Variations"]
                            .split(",")[0]
                            .split(":")[1]
                            .split(" ")[1]
                  }
                  if (textStyles === "Toddler") {
                    textStyles = "onesie"
                    let fix = item["Variations"]
                      .split(",")[0]
                      .split(":")[1]
                      .split(" ")[
                      item["Variations"].split(",")[0].split(":")[1].split(" ")
                        .length - 1
                    ]
                    if (fix === "5-6T") {
                      textSizes = "5T"
                    } else {
                      textSizes = item["Variations"]
                        .split(",")[0]
                        .split(":")[1]
                        .split(" ")[
                        item["Variations"]
                          .split(",")[0]
                          .split(":")[1]
                          .split(" ").length - 1
                      ]
                    }
                  }
                  let sizes = {}
                  let styles = {}
                  let colors = {}
                  if (textColor?.length > 0) {
                    colors = options.colors.find(
                      x => x.label.toLowerCase() === textColor.toLowerCase()
                    )
                  }
                  if (textSizes?.length > 0) {
                    sizes = options.sizes.find(
                      x =>
                        x.label.toLowerCase() === textSizes.toLowerCase().trim()
                    )
                  }

                  if (textStyles?.length > 0) {
                    styles = options.styles.find(
                      x =>
                        x.label.toLowerCase() ===
                        textStyles.toLowerCase().trim()
                    )
                  }

                  let priceVal = null
                  if (sizes?.value && styles?.value) {
                    priceVal = financialList.find(
                      x =>
                        x.sizeId === sizes.value && x.styleId === styles.value
                    )
                  }

                  columns.push({
                    index: i,
                    sku: item["SKU"],
                    saleDate:
                      moment.utc(item["Sale Date"]).format("YYYY-MM-DD") +
                      "T00:00:00.000Z",
                    itemName: item["Item Name"],
                    quantity: item["Quantity"],
                    transactionId: item["Transaction ID"],
                    variant: item["Variations"],
                    orderId: item["Order ID"],
                    shipmentOrder: {
                      nameSurname: item["Ship Name"],
                      city: item["Ship City"],
                      addressLine1: item["Ship Address1"],
                      addressLine2: item["Ship Address2"],
                      zipCode: item["Ship Zipcode"],
                      stateCode: item["Ship State"],
                      country: item["Ship Country"],
                    },
                    size: sizes ? sizes.value : "",
                    color: colors ? colors.value : "",
                    style: styles ? styles.value : "",
                    price: priceVal ? priceVal.price : "",
                    personalization: textPersonalization
                      ? textPersonalization
                      : null,
                  })
                  i++
                } else {
                  notSaveList.push({
                    index: i,
                    sku: item["SKU"],
                    saleDate:
                      moment.utc(item["Sale Date"]).format("YYYY-MM-DD") +
                      "T00:00:00.000Z",
                    itemName: item["Item Name"],
                    quantity: item["Quantity"],
                    transactionId: item["Transaction ID"],
                    variant: item["Variations"],
                    orderId: item["Order ID"],
                  })
                }
              } else if (control) {
                control = false
              }
            } else if (dateProblem) {
              dateProblem = false
            }
          })
        } else {
          parsedData.map((item, index) => {
            const today = new Date()
            const yearStart = today.getFullYear().toString().slice(0, 2)
            let csvYear =
              item["Sale Date"].split("/")[2].length === 4
                ? item["Sale Date"].split("/")[2]
                : yearStart + item["Sale Date"].split("/")[2]
            let csvMonth = item["Sale Date"].split("/")[0]
            let csvDay = item["Sale Date"].split("/")[1]
            let newDate = csvDay + "." + csvMonth + "." + csvYear
            if (!maxDate || isFutureDate(newDate)) {
              if (!orderArrayList.includes(item["Order ID"])) {
                if (
                  item["SKU"] &&
                  item["Sale Date"] &&
                  item["Order ID"] &&
                  (item["Order ID"] !== "3001197594" ||
                    item["Order ID"] !== "2994659793" ||
                    item["Order ID"] !== "3001334060")
                ) {
                  let textStyles = item["Variations"]
                    ? item["Variations"]
                        .split(",")[0]
                        .split(":")[1]
                        .split(" ")[0]
                    : ""

                  let textColor = item["Variations"]
                    ? item["Variations"].split(",")[1].split(":")[1]
                    : ""
                  let textSizes = item["Variations"]
                    ? item["Variations"].split(",")[0].split(":")[1].split(" ")[
                        item["Variations"]
                          .split(",")[0]
                          .split(":")[1]
                          .split(" ").length - 1
                      ]
                    : ""

                  let textPersonalization = ""
                  if (item["Variations"].includes("Month Onesie")) {
                    textStyles = "Onesie"
                    textColor = item["Variations"]
                      ? item["Variations"].split(",")[1].split(":")[1]
                      : ""
                    textSizes = item["Variations"]
                      ? item["Variations"]
                          .split(",")[0]
                          .split(":")[1]
                          .split(" ")[0] +
                        " " +
                        item["Variations"]
                          .split(",")[0]
                          .split(":")[1]
                          .split(" ")[1]
                      : ""
                  }

                  if (item["Variations"].search("Personalization") > 0) {
                    textPersonalization = item["Variations"]
                      ? item["Variations"]
                          .split(",")
                          [item["Variations"].split(",").length - 1].split(
                            ":"
                          )[1]
                      : ""
                  }
                  if (textSizes === "XXL") {
                    textSizes = "2XL"
                  }
                  //- içerirse
                  if (
                    item["Variations"].split(",")[0].search("/") > 0 &&
                    !item["Variations"].includes("Month Onesie")
                  ) {
                    textStyles = item["Variations"]
                      ? item["Variations"]
                          .split(",")[0]
                          .split(":")[1]
                          .split("/")[0]
                          .split(" ")[0]
                      : ""
                    textColor = item["Variations"]
                      ? item["Variations"].split(",")[1].split(":")[1]
                      : ""
                    textSizes = item["Variations"]
                      ? item["Variations"]
                          .split(",")[0]
                          .split(":")[1]
                          .split("/")[1]
                          .trim()
                      : ""
                  } else if (
                    item["Variations"].split(",")[0].search("-") > 0 &&
                    !item["Variations"].includes("Month Onesie")
                  ) {
                    textStyles = item["Variations"]
                      ? item["Variations"]
                          .split(",")[0]
                          .split(":")[1]
                          .split("-")[0]
                          .split(" ")[0]
                      : ""
                    textColor = item["Variations"]
                      ? item["Variations"].split(",")[1].split(":")[1]
                      : ""
                    if (
                      item["Variations"].split(",")[0].split(":")[1].split("-")[
                        item["Variations"]
                          .split(",")[0]
                          .split(":")[1]
                          .split("-").length - 1
                      ] === "XXL"
                    ) {
                      textSizes = "2XL"
                    } else {
                      textSizes = item["Variations"]
                        ? item["Variations"]
                            .split(",")[0]
                            .split(":")[1]
                            .split("-")[
                            item["Variations"]
                              .split(",")[0]
                              .split(":")[1]
                              .split("-").length - 1
                          ]
                        : ""
                    }
                  } else if (textStyles.toLowerCase().trim() === "comfort") {
                    textStyles = "Comfort Colors"
                    if (
                      item["Variations"].split(",")[0].split(":")[1].split(" ")[
                        item["Variations"]
                          .split(",")[0]
                          .split(":")[1]
                          .split(" ").length - 1
                      ] === "XXL"
                    ) {
                      textSizes === "comfort colors 2xl"
                    } else {
                      textSizes = item["Variations"]
                        .split(",")[0]
                        .split(":")[1]
                        .split(" ")[
                        item["Variations"]
                          .split(",")[0]
                          .split(":")[1]
                          .split(" ").length - 1
                      ]
                    }
                  }
                  if (textStyles === "Baby") {
                    textSizes =
                      item["Variations"].split(",")[0].split(":")[1].split(" ")
                        .length > 2
                        ? item["Variations"]
                            .split(",")[0]
                            .split(":")[1]
                            .split(" ")[1] +
                          " " +
                          item["Variations"]
                            .split(",")[0]
                            .split(":")[1]
                            .split(" ")[2]
                        : item["Variations"]
                            .split(",")[0]
                            .split(":")[1]
                            .split(" ")[1]
                  }
                  if (textStyles === "Unisex") {
                    textStyles = "t-shirt"
                  }
                  if (textStyles === "Baby") {
                    textStyles = "onesie"
                    textSizes =
                      item["Variations"].split(",")[0].split(":")[1].split(" ")
                        .length > 2
                        ? item["Variations"]
                            .split(",")[0]
                            .split(":")[1]
                            .split(" ")[1] +
                          " " +
                          item["Variations"]
                            .split(",")[0]
                            .split(":")[1]
                            .split(" ")[2]
                        : item["Variations"]
                            .split(",")[0]
                            .split(":")[1]
                            .split(" ")[1]
                  }

                  if (textStyles === "Toddler") {
                    textStyles = "onesie"
                    let fix = item["Variations"]
                      .split(",")[0]
                      .split(":")[1]
                      .split(" ")[
                      item["Variations"].split(",")[0].split(":")[1].split(" ")
                        .length - 1
                    ]
                    if (fix === "5-6T") {
                      textSizes = "5T"
                    } else {
                      textSizes = item["Variations"]
                        .split(",")[0]
                        .split(":")[1]
                        .split(" ")[
                        item["Variations"]
                          .split(",")[0]
                          .split(":")[1]
                          .split(" ").length - 1
                      ]
                    }
                  }

                  let sizes = {}
                  let styles = {}
                  let colors = {}
                  if (textColor?.length > 0) {
                    colors = options.colors.find(
                      x => x.label.toLowerCase() === textColor.toLowerCase()
                    )
                  }
                  if (textSizes?.length > 0) {
                    sizes = options.sizes.find(
                      x =>
                        x.label.toLowerCase() === textSizes.toLowerCase().trim()
                    )
                  }

                  if (textStyles?.length > 0) {
                    styles = options.styles.find(
                      x =>
                        x.label.toLowerCase() ===
                        textStyles.toLowerCase().trim()
                    )
                  }
                  let priceVal = null
                  if (sizes?.value && styles?.value) {
                    priceVal = financialList.find(
                      x =>
                        x.sizeId === sizes.value && x.styleId === styles.value
                    )
                  }

                  columns.push({
                    index: i,
                    sku: item["SKU"],
                    saleDate:
                      moment.utc(item["Sale Date"]).format("YYYY-MM-DD") +
                      "T00:00:00.000Z",
                    itemName: item["Item Name"],
                    quantity: item["Quantity"],
                    transactionId: item["Transaction ID"],
                    variant: item["Variations"],
                    orderId: item["Order ID"],
                    shipmentOrder: {
                      nameSurname: item["Ship Name"],
                      city: item["Ship City"],
                      addressLine1: item["Ship Address1"],
                      addressLine2: item["Ship Address2"],
                      zipCode: item["Ship Zipcode"],
                      stateCode: item["Ship State"],
                      country: item["Ship Country"],
                    },
                    size: sizes ? sizes.value : "",
                    color: colors ? colors.value : "",
                    style: styles ? styles.value : "",
                    price: priceVal ? priceVal.price : "",
                    personalization: textPersonalization
                      ? textPersonalization
                      : null,
                  })
                  i++
                } else {
                  notSaveList.push({
                    index: i,
                    sku: item["SKU"],
                    saleDate:
                      moment.utc(item["Sale Date"]).format("YYYY-MM-DD") +
                      "T00:00:00.000Z",
                    itemName: item["Item Name"],
                    quantity: item["Quantity"],
                    transactionId: item["Transaction ID"],
                    variant: item["Variations"],
                    orderId: item["Order ID"],
                  })
                }
              } else if (control) {
                control = false
              }
            } else if (dateProblem) {
              dateProblem = false
            }
          })
        }
      } catch (error) {
        setLoading(false)
      }
      if (columns.length > 0) {
        if (!control) {
          toastr.info(
            "Yüklemek istediğiniz dosyadaki siparişlere ait order numaraları daha önce kaydedilmiş olanlar kaydedilmeyecektir."
          )
        }
        if (notSaveList.length > 0) {
          setNotSaveList(notSaveList)
        }
        setData(columns)
      } else {
        if (!dateProblem) {
          toastr.error(
            "Yüklemek istediğiniz dosyadaki siparişlerin günü bu günden daha eski olduğu için kaydedilmeyecektir."
          )
        } else if (!control) {
          toastr.info(
            "Yüklemek istediğiniz dosyadaki siparişlere ait order numaraları daha önce kaydedilmiştir."
          )
        } else {
          toastr.error(
            "Beklenmedik bir hata! Yüklediğiniz dosya ve login olduğunuz kullanıcı adı ile it birimiyle iletişime geçiniz."
          )
        }
      }

      form.setFieldsValue({
        apparelList: columns,
      })
      setLoading(false)
    }
    reader.readAsText(file)
  }

  const handleSaveCsv = () => {
    setQuestionModal(false)
    const recordName = moment(new Date()).format("DD-MM-YYYY")
    const obj = {
      orders: [],
      recordName: recordName,
    }
    setTimeout(() => {
      data.map(item => {
        obj.orders.push({
          sku: item.sku,
          saleDate: item.saleDate,
          itemName: item.itemName,
          quantity: item.quantity,
          transactionId: item.transactionId,
          variant: item.variant,
          orderId: item.orderId,
          categoryId: 1,
          sizeId: item.size,
          styleId: item.style,
          colorId: item.color,
          price: item.price,
          personalization: item.personalization,
          shipmentOrder: {
            nameSurname: item.shipmentOrder.nameSurname,
            city: item.shipmentOrder.city,
            addressLine1: item.shipmentOrder.addressLine1,
            addressLine2: item.shipmentOrder.addressLine2,
            zipCode: item.shipmentOrder.zipCode,
            stateCode: item.shipmentOrder.stateCode,
            country: item.shipmentOrder.country,
          },
        })
      })
      Http.post("api/orders/CreateMultipleOrder", obj)
        .then(res => {
          if (res.success) {
            toastr.success(
              `Csv file upload has been success. It may take some time for the data to be saved.`
            )
            setTimeout(() => {
              navigate("/apparel-list")
            }, 300)
            setLoading(false)
          } else {
            setLoading(false)
            toastr.error(res?.Message)
          }
        })
        .catch(function (error) {
          setLoading(false)
          toastr.error(error?.response?.data?.Message)
        })
    }, 300)
  }
  useEffect(() => {
    form.setFieldsValue({
      apparelList: data,
    })
  }, [data])

  const handleChange = (row, value, type) => {
    setLoading(true)
    let sizesValue = type === "size" ? value.value : data[row.index]?.size
    let stylesValue = type === "style" ? value.value : data[row.index]?.style
    if (type === "color") {
      data[row.index].color = value.value
      setData(data)
      setTimeout(() => {
        form.setFieldsValue({
          apparelList: data,
        })
      }, 100)
      setLoading(false)
    } else {
      let finderPrice = financialList.find(
        x => x.sizeId === sizesValue && x.styleId === stylesValue
      )
      if (sizesValue === "" && stylesValue === "") {
        //2si boşsa
        data[row.index].price = ""
        data[row.index].size = sizesValue
        data[row.index].style = stylesValue
        setData(data)
        setLoading(false)
      } else if (sizesValue !== "" && stylesValue !== "") {
        //2si doluysa
        data[row.index].size = sizesValue
        data[row.index].style = stylesValue
        data[row.index].price = finderPrice?.price ? finderPrice?.price : ""
        setData(data)
        setTimeout(() => {
          setLoading(false)
          form.setFieldsValue({
            apparelList: data,
          })
        }, 100)
      } else if (
        (stylesValue !== "" && sizesValue === "") ||
        (stylesValue === "" && sizesValue !== "")
      ) {
        // herhangi biri boşsa
        setData(form.getFieldValue()?.apparelList)
        setLoading(false)
      }
    }
  }

  const handleFilterData = () => {
    setLoading(true)
    setPage(1)
    const filter = data.filter(
      x => x.size === "" || x.color === "" || x.style === "" || !x.price
    )
    if (filter[0].index % 10 === 0) {
      setPage(Math.ceil(filter[0].index / 10 + 1))
      setLoading(false)
    } else {
      setPage(Math.ceil(filter[0].index / 10))
      setLoading(false)
    }
  }
  return (
    <React.Fragment>
      <div className="page-content mb-3">
        <style>{css}</style>
        {questionModal && (
          <Modal
            title="Dikkat"
            open={true}
            centered
            maskClosable={false}
            onOk={() => {
              handleParse(true)
            }}
            onCancel={() => {
              handleParse(false)
            }}
            okText="Yes"
            cancelText="No"
          >
            <p>
              İlk defa veri kaydediyorsunuz. Bu günden önceki kayıtlarınız
              kaydedilmeyecektir. Onaylıyor musunuz?
            </p>
          </Modal>
        )}
        <Spin spinning={loading}>
          {financialList.length > 0 ? (
            <Card>
              <Row
                gutter={[16, 16]}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Col lg={6}>
                  <h4>Apparel Add</h4>
                </Col>
                <Col
                  lg={18}
                  className="d-flex align-items-center justify-content-end gap-2  mb-3"
                >
                  <Button
                    type="dashed"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    onClick={() => {
                      navigate("/apparel-list")
                      setFileName(null)
                    }}
                    icon={
                      <i className="bx bx-arrow-back font-size-16 align-middle mr-2"></i>
                    }
                  >
                    Return to Apparel List
                  </Button>
                  {data.length > 0 && (
                    <Button
                      type="dashed"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                      onClick={() => {
                        setData([])
                        setPage(1)
                        setFileName(null)
                      }}
                      icon={
                        <i className="bx bx-cloud-upload font-size-16 align-middle mr-2"></i>
                      }
                    >
                      Tekrar CSV Yükle
                    </Button>
                  )}
                </Col>
                {data.find(
                  x =>
                    x.size === "" ||
                    x.style === "" ||
                    x.color === "" ||
                    x.price === "" ||
                    !x.price
                ) && (
                  <Col
                    lg={24}
                    className="d-flex align-items-center justify-content-end gap-2  mb-3"
                  >
                    <Button
                      type={
                        data.filter(
                          x =>
                            x.size === "" ||
                            x.style === "" ||
                            x.color === "" ||
                            x.price === "" ||
                            !x.price
                        ).length > 0
                          ? ""
                          : "dashed"
                      }
                      className={
                        data.filter(
                          x =>
                            x.size === "" ||
                            x.style === "" ||
                            x.color === "" ||
                            x.price === "" ||
                            !x.price
                        ).length > 0
                          ? "btn btn-danger"
                          : ""
                      }
                      onClick={() => handleFilterData()}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      Eksik Verinin Sayfasını Göster (
                      {
                        data.filter(
                          x =>
                            x.size === "" ||
                            x.style === "" ||
                            x.color === "" ||
                            x.price === "" ||
                            !x.price
                        ).length
                      }
                      )
                    </Button>
                  </Col>
                )}
                {data.length > 0 && (
                  <>
                    <Col
                      lg={16}
                      className="d-flex align-items-center justify-content-start gap-2  mb-3"
                    >
                      <Row>
                        <Col lg={24}>
                          <Form
                            form={formV1}
                            style={{ width: "100%", maxWidth: 700 }}
                          >
                            <Form.Item
                              label="Record Name:"
                              name="saveNameFile"
                              rules={[
                                {
                                  required: true,
                                  message: "Kayıt Adı alanı zorunludur!",
                                },
                              ]}
                            >
                              <Space.Compact>
                                <Input
                                  style={{
                                    width: "100%",
                                  }}
                                  disabled
                                  defaultValue={moment(new Date()).format(
                                    "DD-MM-YYYY"
                                  )}
                                />
                              </Space.Compact>
                            </Form.Item>
                          </Form>
                        </Col>
                        {fileName?.length > 0 && (
                          <Col lg={24}>
                            <span
                              style={{ fontWeight: "bold", marginLeft: 10 }}
                            >
                              Record Name:
                            </span>{" "}
                            {fileName}
                          </Col>
                        )}
                      </Row>
                    </Col>

                    <Col
                      lg={8}
                      className="d-flex align-items-center justify-content-end gap-2  mb-3"
                    >
                      <Button
                        type={
                          data.filter(
                            x =>
                              x.size === "" ||
                              x.style === "" ||
                              x.color === "" ||
                              x.price === "" ||
                              !x.price
                          ).length > 0
                            ? "dashed"
                            : ""
                        }
                        className={
                          data.filter(
                            x =>
                              x.size === "" ||
                              x.style === "" ||
                              x.color === "" ||
                              x.price === "" ||
                              !x.price
                          ).length > 0
                            ? ""
                            : "btn btn-success"
                        }
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                        disabled={
                          data.filter(
                            x =>
                              x.size === "" ||
                              x.style === "" ||
                              x.color === "" ||
                              x.price === "" ||
                              !x.price
                          ).length > 0
                            ? true
                            : false
                        }
                        onClick={() => {
                          setLoading(true)
                          handleSaveCsv()
                        }}
                        loading={loading}
                      >
                        Kaydet ({data.length})
                      </Button>
                    </Col>
                  </>
                )}
              </Row>
              <Row>
                {data.length === 0 && (
                  <Col lg={24} className="mb-2">
                    <div className="mb-3">
                      This area provides the ability to upload a drag and drop
                      file containing csv file previews.
                    </div>
                    <Form>
                      <Dropzone
                        onDrop={acceptedFiles => {
                          handleFileChange(acceptedFiles)
                        }}
                        multiple={false}
                        accept={{
                          "file/csv": [".csv"],
                        }}
                      >
                        {({ getRootProps, getInputProps }) => (
                          <div className="dropzone">
                            <div
                              className="dz-message needsclick mt-2"
                              {...getRootProps()}
                            >
                              <input type="file" {...getInputProps()} />
                              <div className="mb-3">
                                <i className="display-4 text-muted bx bxs-cloud-upload " />
                              </div>
                              {file.name ? (
                                <h6 style={{ color: "red" }}>
                                  Upload File Name : {file.name}
                                </h6>
                              ) : (
                                <h4>Drop files here or click to upload.</h4>
                              )}
                            </div>
                          </div>
                        )}
                      </Dropzone>
                      <Col
                        lg={24}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "end",
                        }}
                      >
                        <Button
                          color="primary"
                          className="btn btn-primary"
                          disabled={data.length > 0 ? true : false}
                          onClick={() => {
                            if (file.type === "text/csv") {
                              setLoading(true)
                              setTimeout(() => {
                                if (haveOrderBefore) {
                                  setQuestionModal(true)
                                } else {
                                  handleParse(false)
                                }
                              }, 300)
                            } else {
                              toastr.error(`Dosya Yükleyiniz!`)
                            }
                          }}
                          style={{
                            marginTop: 15,
                            height: 35,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            width: "100%",
                          }}
                        >
                          Parse
                        </Button>
                      </Col>
                    </Form>
                  </Col>
                )}

                {error.length > 2 && (
                  <Col lg={24}>
                    <h4 style={{ textAlign: "center", color: "red" }}>
                      {error}
                    </h4>
                  </Col>
                )}
                {data.filter(
                  x =>
                    x.size === "" ||
                    x.style === "" ||
                    x.color === "" ||
                    x.price === "" ||
                    !x.price
                ).length > 0 && (
                  <span style={{ color: "red" }}>
                    * Kayıt işlemi yapabilmek için lütfen tablodaki kırmızı
                    alanla işaretlenmiş verileri kontrol ederek doldurunuz!
                    Kontrol etmek için sağ üstteki butonu kullanabilirisiniz.
                  </span>
                )}
                {data.length > 0 && (
                  <>
                    <Col lg={24}>
                      <Form name="dynamic_form_item" form={form}>
                        <Form.List name="apparelList">
                          {_ => {
                            return (
                              <Table
                                name="Apparel Listesi"
                                bordered
                                size="small"
                                columns={columns}
                                dataSource={data}
                                className="table table-centered"
                                scroll={{ x: 240 }}
                                pagination={{
                                  total: data.length,
                                  current: currentPage,
                                  onChange: (page, pageSize) => {
                                    setPage(page)
                                    if (currentPage !== page) {
                                      form.setFieldsValue({
                                        apparelList: data,
                                      })
                                      // setData(form.getFieldsValue().apparelList)
                                    }
                                  },
                                }}
                              />
                            )
                          }}
                        </Form.List>
                      </Form>
                    </Col>
                  </>
                )}
                {notSaveList.length > 0 && (
                  <Col lg={24} style={{ color: "red" }}>
                    * {notSaveList.length} adet order eklenememiştir.
                    <span
                      style={{
                        color: "black",
                        opacity: "0.7",
                        marginLeft: 18,
                      }}
                    >
                      Siparişin sku, sale date ve ya order numara bilgisi eksik
                      olabilir.{" "}
                    </span>{" "}
                    <br />* Eklenmeyen order numaraları.{" "}
                    <span
                      style={{
                        color: "black",
                        marginLeft: 8,
                      }}
                    >
                      {notSaveList.map((item, index) => {
                        if (index === notSaveList.length - 1) {
                          return item.orderId
                        } else {
                          return item.orderId + ", "
                        }
                      })}
                    </span>
                  </Col>
                )}
              </Row>
            </Card>
          ) : (
            <Alert
              message="Sistem Mesajı!"
              description="Apparel işlemleriniz için sistem admin tarafından size ait ürün baz fiyatı veya fiyatlarının belirlemesi gerekemektedir. İşlem yapmak için iletişime geçiniz!"
              type="error"
            />
          )}
        </Spin>
      </div>
    </React.Fragment>
  )
}

export default ApparelAdd
