import React, { useEffect } from "react"
import { Modal, Form, Input, Row, Col } from "antd"
import * as Http from "utils/http.helper"
import toastr from "toastr"
import "toastr/build/toastr.min.css"
const { TextArea } = Input

const VariantModal = ({
  variantModal,
  setVariantModal,
  setLoading,
  getTableData,
}) => {
  const [form] = Form.useForm()

  useEffect(() => {
    if (variantModal.data.variant) {
      form.setFieldsValue({
        variant: variantModal.data.variant,
      })
    }
  }, [])

  const onFinish = values => {
    setLoading(true)

    let obj = {
      variant: values.variant,
      orderId: variantModal.data?.id,
    }
    Http.post("api/orders/editEngraving", obj)
      .then(res => {
        setLoading(false)
        if (res.success) {
          getTableData(null)
          setVariantModal({ show: false, data: null })
          toastr.success(`Process successful.`)
        }
      })
      .catch(function (error) {
        setLoading(false)
      })
  }

  return (
    <>
      <Modal
        size="small"
        maskClosable={false}
        title={
          <>
            Edit Variant{" "}
            <span style={{ fontSize: 14 }}>
              <span style={{ opacity: "0.3" }}>[ Order: </span>
              {variantModal?.data?.orderId}{" "}
              <span style={{ opacity: "0.3" }}>| Sku: </span>
              {variantModal?.data?.sku}
              <span style={{ opacity: "0.3" }}> ]</span>
            </span>
          </>
        }
        open={variantModal.show}
        onOk={() =>
          form
            .validateFields()
            .then(val => {
              onFinish(val)
            })
            .catch(err => {})
        }
        okText="Save"
        onCancel={() => setVariantModal({ show: false, data: null })}
      >
        <Form
          form={form}
          layout="vertical"
          onSubmit={e => {
            e.preventDefault()
            return false
          }}
        >
          <Row gutter={[16, 16]}>
            <Col lg={24}>
              <Form.Item
                label="Variant"
                name="variant"
                rules={[
                  {
                    required: true,
                    message: "This field is required!",
                  },
                ]}
              >
                <TextArea rows={4} />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  )
}

export default VariantModal
