import React, { useEffect, useRef, useCallback } from "react"
import { useLocation } from "react-router-dom"
import PropTypes from "prop-types"

// //Import Scrollbar
import SimpleBar from "simplebar-react"

// MetisMenu
import MetisMenu from "metismenujs"
import withRouter from "components/Common/withRouter"
import { Link } from "react-router-dom"

//i18n
import { withTranslation } from "react-i18next"
import { Divider } from "antd"

const SidebarContent = props => {
  const ref = useRef()

  const activateParentDropdown = useCallback(item => {
    item.classList.add("active")
    const parent = item.parentElement
    const parent2El = parent.childNodes[1]

    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show")
    }

    if (parent) {
      parent.classList.add("mm-active")
      const parent2 = parent.parentElement

      if (parent2) {
        parent2.classList.add("mm-show") // ul tag

        const parent3 = parent2.parentElement // li tag

        if (parent3) {
          parent3.classList.add("mm-active") // li
          parent3.childNodes[0].classList.add("mm-active") //a
          const parent4 = parent3.parentElement // ul
          if (parent4) {
            parent4.classList.add("mm-show") // ul
            const parent5 = parent4.parentElement
            if (parent5) {
              parent5.classList.add("mm-show") // li
              parent5.childNodes[0].classList.add("mm-active") // a tag
            }
          }
        }
      }
      scrollElement(item)
      return false
    }
    scrollElement(item)
    return false
  }, [])

  const removeActivation = items => {
    for (var i = 0; i < items.length; ++i) {
      var item = items[i]
      const parent = items[i].parentElement

      if (item && item.classList.contains("active")) {
        item.classList.remove("active")
      }
      if (parent) {
        const parent2El =
          parent.childNodes && parent.childNodes.lenght && parent.childNodes[1]
            ? parent.childNodes[1]
            : null
        if (parent2El && parent2El.id !== "side-menu") {
          parent2El.classList.remove("mm-show")
        }

        parent.classList.remove("mm-active")
        const parent2 = parent.parentElement

        if (parent2) {
          parent2.classList.remove("mm-show")

          const parent3 = parent2.parentElement
          if (parent3) {
            parent3.classList.remove("mm-active") // li
            parent3.childNodes[0].classList.remove("mm-active")

            const parent4 = parent3.parentElement // ul
            if (parent4) {
              parent4.classList.remove("mm-show") // ul
              const parent5 = parent4.parentElement
              if (parent5) {
                parent5.classList.remove("mm-show") // li
                parent5.childNodes[0].classList.remove("mm-active") // a tag
              }
            }
          }
        }
      }
    }
  }

  const path = useLocation()
  const activeMenu = useCallback(() => {
    const pathName = path.pathname
    let matchingMenuItem = null
    const ul = document.getElementById("side-menu")
    const items = ul.getElementsByTagName("a")
    removeActivation(items)

    for (let i = 0; i < items.length; ++i) {
      if (pathName === items[i].pathname) {
        matchingMenuItem = items[i]
        break
      }
    }
    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem)
    }
  }, [path.pathname, activateParentDropdown])

  useEffect(() => {
    ref.current.recalculate()
  }, [])

  useEffect(() => {
    new MetisMenu("#side-menu")
    activeMenu()
  }, [])

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" })
    activeMenu()
  }, [activeMenu])

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300
      }
    }
  }

  return (
    <React.Fragment>
      <SimpleBar className="h-100" ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            <li className="menu-title">{props.t("Menu")} </li>
            {/* company admin */}

            <li>
              <Link to="/dashboard">
                <i className="bx bx-home-circle"></i>
                <span>{props.t("Dashboards")}</span>
              </Link>
            </li>

            {/* ------------------------ APPAREL --------------------------- */}
            {/* System Admin */}
            {JSON.parse(localStorage.getItem("authUserV0"))?.roleId === 8 && (
              <li>
                <Link to="/#" className="has-arrow">
                  <i className="bx bx-wallet"></i>
                  <span>Apparel</span>
                </Link>
                <ul className="sub-menu">
                  <li>
                    <Link to="/style-management">
                      <i className="bx bx-font-family"></i>
                      <span>Style Management</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/size-management">
                      <i className="bx bx-font-size"></i>
                      <span>Size Management</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/color-management">
                      <i className="bx bx-font-color"></i>
                      <span>Color Management</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/price-management">
                      <i className="bx bx bx-money"></i>
                      Price Management
                    </Link>
                  </li>
                </ul>
              </li>
            )}
            {/* Company Admin / Customer Admin / Company Worker*/}
            {JSON.parse(localStorage.getItem("authUserV0"))?.roleId !== 8 &&
              JSON.parse(localStorage.getItem("authUserV0"))?.userCategories &&
              JSON.parse(
                localStorage.getItem("authUserV0")
              )?.userCategories.find(x => x.categoryId === 1) && (
                <li>
                  <Link to="/#" className="has-arrow">
                    <i className="bx bx-wallet"></i>
                    <span>Apparel</span>
                  </Link>
                  <ul className="sub-menu">
                    <li>
                      <Link to="/apparel-list">View Order </Link>
                    </li>
                    {JSON.parse(localStorage.getItem("authUserV0"))?.roleId !==
                      7 && (
                      <li>
                        <Link to="/apparel-financial">Financial Reports</Link>
                      </li>
                    )}

                    {JSON.parse(localStorage.getItem("authUserV0"))?.roleId ===
                      7 && (
                      <li>
                        <Link to="/apparel-printer">Printer</Link>
                      </li>
                    )}
                  </ul>
                </li>
              )}

            {/* ------------------------ ENGRAVİNG --------------------------- */}
            {/* System Admin */}
            {JSON.parse(localStorage.getItem("authUserV0"))?.roleId === 8 && (
              <li>
                <Link to="/#" className="has-arrow">
                  <i className="bx  bx-id-card"></i>
                  <span>Engraving</span>
                </Link>
                <ul className="sub-menu">
                  <li>
                    <Link to="/engraving-image-management">
                      <i className="bx  bx-image"></i>
                      <span>Image M.</span>
                    </Link>
                  </li>
                </ul>
              </li>
            )}
            {/* Company Admin / Customer Admin / Company Worker */}
            {JSON.parse(localStorage.getItem("authUserV0"))?.roleId !== 8 &&
              JSON.parse(localStorage.getItem("authUserV0"))?.userCategories &&
              JSON.parse(
                localStorage.getItem("authUserV0")
              )?.userCategories.find(x => x.categoryId === 3) && (
                <li>
                  <Link to="/#" className="has-arrow">
                    <i className="bx  bx-id-card"></i>
                    <span>Engraving</span>
                  </Link>
                  <ul className="sub-menu">
                    <li>
                      <Link to="/engraving-list">View Order </Link>
                    </li>
                    {(JSON.parse(localStorage.getItem("authUserV0"))?.roleId ===
                      2 ||
                      JSON.parse(localStorage.getItem("authUserV0"))?.roleId ===
                        1) && (
                      <li>
                        <Link to="/waiting-for-desing">Waiting For Design</Link>
                      </li>
                    )}

                    {JSON.parse(localStorage.getItem("authUserV0"))?.roleId ===
                      1 && (
                      <>
                        <li>
                          <Link to="/engraving-faulty-order">Faulty Order</Link>
                        </li>
                        <li>
                          <Link to="/engraving-stock-tracking">
                            Stock Tracking
                          </Link>
                        </li>
                        <li>
                          <Link to="/engraving-price-management">Price M.</Link>
                        </li>
                      </>
                    )}
                    {JSON.parse(localStorage.getItem("authUserV0"))?.roleId !==
                      7 && (
                      <li>
                        <Link to="/engraving-financial">Financial Reports</Link>
                      </li>
                    )}
                    {JSON.parse(localStorage.getItem("authUserV0"))?.roleId ===
                      7 && (
                      <li>
                        <Link to="/engraving-printer">Printer</Link>
                      </li>
                    )}
                  </ul>
                </li>
              )}

            {/* ------------------------ DTF --------------------------- */}
            {/* Company Admin / Customer Admin / Company Worker */}
            {JSON.parse(localStorage.getItem("authUserV0"))?.roleId !== 8 &&
              JSON.parse(localStorage.getItem("authUserV0"))?.userCategories &&
              JSON.parse(
                localStorage.getItem("authUserV0")
              )?.userCategories.find(x => x.categoryId === 2) && (
                <li>
                  <Link to="/#" className="has-arrow">
                    <i className="bx bx-wallet"></i>
                    <span>DTF</span>
                  </Link>
                  <ul className="sub-menu">
                    <li>
                      <Link to="/dtf-list">View Order </Link>
                    </li>
                    {JSON.parse(localStorage.getItem("authUserV0"))?.roleId !==
                      7 && (
                      <li>
                        <Link to="/dtf-financial">Financial Reports</Link>
                      </li>
                    )}
                    {JSON.parse(localStorage.getItem("authUserV0"))?.roleId ===
                      1 && (
                      <li>
                        <Link to="/dtf-management">Price M.</Link>
                      </li>
                    )}
                    {JSON.parse(localStorage.getItem("authUserV0"))?.roleId ===
                      7 &&
                      JSON.parse(localStorage.getItem("authUserV0")).actions &&
                      JSON.parse(localStorage.getItem("authUserV0"))
                        .actions[0] === "Shipment" && (
                        <li>
                          <Link to="/dtf-printer">Printer</Link>
                        </li>
                      )}
                  </ul>
                </li>
              )}

            {/* ------------------------ UVDTF --------------------------- */}
            {/* Company Admin / Customer Admin / Company Worker */}
            {JSON.parse(localStorage.getItem("authUserV0"))?.roleId !== 8 &&
              JSON.parse(localStorage.getItem("authUserV0"))?.userCategories &&
              JSON.parse(
                localStorage.getItem("authUserV0")
              )?.userCategories.find(x => x.categoryId === 4) && (
                <li>
                  <Link to="/#" className="has-arrow">
                    <i className="bx bx-wallet"></i>
                    <span>UVDTF</span>
                  </Link>
                  <ul className="sub-menu">
                    <li>
                      <Link to="/uvdtf-list">View Order </Link>
                    </li>
                    {JSON.parse(localStorage.getItem("authUserV0"))?.roleId !==
                      7 && (
                      <li>
                        <Link to="/uvdtf-financial">Financial Reports</Link>
                      </li>
                    )}
                    {JSON.parse(localStorage.getItem("authUserV0"))?.roleId ===
                      1 && (
                      <li>
                        <Link to="/uvdtf-management">Price M.</Link>
                      </li>
                    )}
                    {JSON.parse(localStorage.getItem("authUserV0"))?.roleId ===
                      7 &&
                      JSON.parse(localStorage.getItem("authUserV0")).actions &&
                      JSON.parse(localStorage.getItem("authUserV0"))
                        .actions[0] === "Shipment" && (
                        <li>
                          <Link to="/uvdtf-printer">Printer</Link>
                        </li>
                      )}
                  </ul>
                </li>
              )}

            {/* ------------------------ PACKAGE --------------------------- */}
            {/* Company Admin */}
            {JSON.parse(localStorage.getItem("authUserV0"))?.roleId === 1 &&
              JSON.parse(localStorage.getItem("authUserV0"))?.userCategories &&
              JSON.parse(
                localStorage.getItem("authUserV0")
              )?.userCategories.find(x => x.categoryId === 5) && (
                <li>
                  <Link to="/#" className="has-arrow">
                    <i className="bx bx-wallet"></i>
                    <span>Package</span>
                  </Link>
                  <ul className="sub-menu">
                    <li>
                      <Link to="/package-list-download">Received </Link>
                    </li>
                    <li>
                      <Link to="/package-financial">Financial Reports</Link>
                    </li>
                    {JSON.parse(localStorage.getItem("authUserV0"))?.roleId ===
                      1 && (
                      <li>
                        <Link to="/package-price-management">Price M.</Link>
                      </li>
                    )}
                  </ul>
                </li>
              )}
            {/* Customer Admin */}
            {JSON.parse(localStorage.getItem("authUserV0"))?.roleId === 2 &&
              JSON.parse(localStorage.getItem("authUserV0"))?.userCategories &&
              JSON.parse(
                localStorage.getItem("authUserV0")
              )?.userCategories.find(x => x.categoryId === 5) && (
                <li>
                  <Link to="/#" className="has-arrow">
                    <i className="bx bx-wallet"></i>
                    <span>Package</span>
                  </Link>
                  <ul className="sub-menu">
                    <li>
                      <Link to="/package-list">Add Package </Link>
                    </li>
                    <li>
                      <Link to="/package-financial">Financial Reports</Link>
                    </li>
                  </ul>
                </li>
              )}
            {/* ------------------------------------------------------------------------------------------------------------------------------------------------------------- */}
            {/* Company Admin Extra */}
            {JSON.parse(localStorage.getItem("authUserV0"))?.roleId === 1 && (
              <>
                <Divider style={{ background: "white", opacity: "0.1" }} />
                <li>
                  <Link to="/#" className="has-arrow">
                    <i className="bx bxs-buildings"></i>
                    <span>Customer</span>
                  </Link>
                  <ul className="sub-menu">
                    <li>
                      <Link to="/company-list">
                        <span>Customer M.</span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/user-list">
                        <span>Customer User M.</span>
                      </Link>
                    </li>
                  </ul>
                </li>
              </>
            )}
            {/* Customer Admin */}
            {/* {JSON.parse(localStorage.getItem("authUserV0"))?.roleId === 2 && (
              <li>
                <Divider style={{ background: "white", opacity: "0.1" }} />
                <Link to="/user-list">
                  <i className="bx bx-user"></i>
                  <span>Customer User</span>
                </Link>
              </li>
            )} */}

            {/* system admin */}
            {JSON.parse(localStorage.getItem("authUserV0"))?.roleId === 8 && (
              <li>
                <Link to="/#" className="has-arrow">
                  <i className="bx bxs-buildings"></i>
                  <span>Company</span>
                </Link>

                <ul className="sub-menu">
                  <li>
                    <Link to="/main-company-list">
                      <i className="bx bxs-building-house"></i>
                      <span>Company M.</span>
                    </Link>
                  </li>

                  <li>
                    <Link to="/main-company-user-list">
                      <i className="bx bxs-user-detail"></i>
                      <span>Company Admin M.</span>
                    </Link>
                  </li>
                </ul>
              </li>
            )}
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  )
}

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(SidebarContent))
